import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { App } from "./App";
import { FullScreenFlexContainer, GlobalStyle } from "./app/GlobalStyle";
import { ACCESS_TOKEN_STORAGE_KEY, AuthProvider } from "./app/auth";
import { getConfigForEnv } from "./app/config";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme";

const config = getConfigForEnv();

const httpLink = createHttpLink({ uri: config.graphqlUri });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

  return {
    headers: {
      ...headers,
      authorization: token == null ? "" : `Bearer ${token}`,
    },
  };
});

const container = document.getElementById("root");
// es-lint-disable-next-line @typescript-eslint/no-null-assertion
const root = createRoot(container!);

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      DeviceInfo: {
        keyFields: ["deviceId"],
      },
    },
  }),
  link: authLink.concat(httpLink),
});
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <FullScreenFlexContainer>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </FullScreenFlexContainer>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();