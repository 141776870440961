import { Col, Loader, Spacing } from "@hark-tech/components";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Reset, SignIn, useAuth } from "./app/auth";
import { DeleteMe } from "./app/delete-me";
import { Routes as EcomonRoutes } from "./app/routes";

export function App(): JSX.Element {
  const { isFetchingUser } = useAuth();
  if (isFetchingUser) {
    return <Loader />;
  }
  return (
    <Col style={{ alignItems: "center", flexGrow: 1 }}>
      <Col
        style={{
          height: "100%",
          padding: `${Spacing.SMALL} ${Spacing.MEDIUM}`,
          width: "clamp(256px, 20%, 40%)",
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path={EcomonRoutes.SIGN_IN} element={<SignIn />} />
            <Route path={EcomonRoutes.RESET_PASSWORD} element={<Reset />} />
            <Route
              path={EcomonRoutes.INDEX}
              element={
                <RequireAuth>
                  <DeleteMe />
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </Col>
    </Col>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (currentUser == null) {
    return (
      <Navigate
        to={{
          pathname: EcomonRoutes.SIGN_IN,
        }}
        state={{ from: location }}
      />
    );
  }

  return children;
}
