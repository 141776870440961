import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ClaimId: any;
  /** Date, given as an ISO 8601 string. Parsed as UTC if time offset is not given. */
  Date: any;
  DeviceId: any;
  /**
   * Global location number, used world wide to identify legal, physical and
   * functional locations. These are used as identifiers e.g. companies and power
   * meter ids.
   */
  Gln: any;
  IntermediaryId: any;
  JSON: any;
  MeterId: any;
  /** Nothing */
  Void: any;
};

export type Activation = {
  __typename?: 'Activation';
  status: ActivationStatus;
  updatedAt: Scalars['Date'];
};

export enum ActivationStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED'
}

export type AddChargingLocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type AddChargingLocationPayload = AddChargingLocationSuccessPayload | ChargingLocationAlreadyExistsPayload;

export enum AddChargingLocationResponseType {
  AlreadyExists = 'ALREADY_EXISTS',
  Success = 'SUCCESS'
}

export type AddChargingLocationSuccessPayload = {
  __typename?: 'AddChargingLocationSuccessPayload';
  chargingLocation: ChargingLocation;
  type: AddChargingLocationResponseType;
};

export type AddChargingPermissionInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddChargingPermissionPayload = {
  __typename?: 'AddChargingPermissionPayload';
  modifiedPermission: Permission;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddDevicePermissionsInput = {
  deviceIdentifiers: Array<DeviceIdentifierInput>;
  userIdentifier: UserIdentifierInput;
};

export type AddDevicePermissionsPayload = {
  __typename?: 'AddDevicePermissionsPayload';
  modifiedPermissions: Array<Permission>;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput = {
  deviceId: Scalars['String'];
  limitInWattHours: Scalars['Int'];
};

export type AddHourlyConsumptionLimitEstimationWarningAlertSettingInput = {
  deviceId: Scalars['String'];
  limitInWatts: Scalars['Int'];
};

export type AddIntermediaryApiKeyInput = {
  name: Scalars['String'];
};

export type AddIntermediaryWebhookNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  callbackUrl: Scalars['String'];
  intermediaryId?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
};

export type AddIntermediaryWebhookNotificationChannelToAlertSettingPayload = {
  __typename?: 'AddIntermediaryWebhookNotificationChannelToAlertSettingPayload';
  notificationChannel: IntermediaryWebhookNotificationChannel;
};

export type AddPriceWarningAlertSettingInput = {
  energyBiddingZone: EnergyBiddingZone;
};

export type AddPushNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID'];
};

export type AddPushNotificationChannelToAlertSettingPayload = {
  __typename?: 'AddPushNotificationChannelToAlertSettingPayload';
  notificationChannel: PushNotificationChannel;
};

export type AddUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddUserPayload = {
  __typename?: 'AddUserPayload';
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddUserSessionInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddUserSessionPayload = {
  __typename?: 'AddUserSessionPayload';
  session: SessionInfo;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export enum AlertChannelType {
  IntermediaryWebhook = 'INTERMEDIARY_WEBHOOK',
  PushNotification = 'PUSH_NOTIFICATION'
}

export type AlertSetting = EstimatedHourlyActiveEnergyLimitWarningAlertSetting | HourlyConsumptionLimitEstimationWarningAlertSetting | PriceWarningAlertSetting;

export type AlertSettingCreator = EndUserAlertSettingCreator | IntermediaryAlertSettingCreator;

export enum AlertSettingCreatorType {
  EndUser = 'END_USER',
  Intermediary = 'INTERMEDIARY'
}

export enum AlertType {
  EstimatedHourlyActiveEnergyLimitWarning = 'ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING',
  HourlyConsumptionLimitEstimationWarning = 'HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING',
  PriceWarning = 'PRICE_WARNING'
}

export enum CapacityModelType {
  FuseAndVoltageSize = 'FuseAndVoltageSize',
  MonthlyPeaksAtDifferentDays = 'MonthlyPeaksAtDifferentDays',
  MonthlyPeaksAtDifferentDaysLinearWithBase = 'MonthlyPeaksAtDifferentDaysLinearWithBase',
  WeightedYearlyRollingPeaksInDifferentWeeks = 'WeightedYearlyRollingPeaksInDifferentWeeks'
}

/** The capacity steps includes as range in Wh and a price for the capacity step */
export type CapacityStep = {
  __typename?: 'CapacityStep';
  capacityStepPrice: PriceSpecification;
  rangeInWh: EnergyRangeInWh;
};

export type ChargingLocation = {
  __typename?: 'ChargingLocation';
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type ChargingLocationAlreadyExistsPayload = {
  __typename?: 'ChargingLocationAlreadyExistsPayload';
  type: AddChargingLocationResponseType;
};

export type ChargingStatisticsEntry = {
  __typename?: 'ChargingStatisticsEntry';
  costSum: Scalars['Float'];
  date: Scalars['String'];
  estimatedSavings: Scalars['Float'];
  kw: Range;
  kwhSum: Scalars['Float'];
  nonSmartCostSum: Scalars['Float'];
  nonSmartPrice: Range;
  price: Range;
  priceCurrency: Currency;
  smartSession: Scalars['Boolean'];
};

export type ChargingStatisticsInput = {
  chargingLocationId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['String']>;
  resolution?: InputMaybe<ChargingStatisticsResolution>;
  startDate: Scalars['Date'];
  type?: InputMaybe<ChargingStatisticsType>;
  utcOffset?: InputMaybe<Scalars['Float']>;
};

export type ChargingStatisticsPayload = {
  __typename?: 'ChargingStatisticsPayload';
  entries: Array<ChargingStatisticsEntry>;
};

export enum ChargingStatisticsResolution {
  Day = 'DAY',
  HalfHour = 'HALF_HOUR',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum ChargingStatisticsType {
  Charger = 'CHARGER',
  Hvac = 'HVAC',
  Vehicle = 'VEHICLE'
}

export type ConfiguredIntegrationVendorDevice = ConfiguredIntegrationVendorVehicle;

export type ConfiguredIntegrationVendorInfo = {
  __typename?: 'ConfiguredIntegrationVendorInfo';
  devices: Array<ConfiguredIntegrationVendorDevice>;
  status: IntegrationVendorStatus;
  vendor: IntegrationVendor;
};

export type ConfiguredIntegrationVendorVehicle = {
  __typename?: 'ConfiguredIntegrationVendorVehicle';
  chargeState: ConfiguredIntegrationVendorVehicleChargeState;
  chargingLocationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isReachable: Scalars['Boolean'];
  isSmartChargingCapable: Scalars['Boolean'];
  location: ConfiguredIntegrationVendorVehicleLocationState;
  model: Scalars['String'];
  smartChargingPolicy?: Maybe<SmartChargingPolicy>;
  vendor: IntegrationVendor;
  yearOfProduction: Scalars['Int'];
};

export type ConfiguredIntegrationVendorVehicleChargeState = {
  __typename?: 'ConfiguredIntegrationVendorVehicleChargeState';
  batteryCapacity: Scalars['Float'];
  batteryLevel: Scalars['Int'];
  chargeRate: Scalars['Float'];
  chargeTimeRemaining: Scalars['Int'];
  isCharging: Scalars['Boolean'];
  isFullyCharged: Scalars['Boolean'];
  isPluggedIn: Scalars['Boolean'];
  range: Scalars['Float'];
};

export type ConfiguredIntegrationVendorVehicleLocationState = GpsDisabledConfiguredIntegrationVendorVehicleLocationState | GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState | LocationAvailableConfiguredIntegrationVendorVehicleLocationState;

export enum ConfiguredIntegrationVendorVehicleLocationStateType {
  GpsDisabled = 'GPS_DISABLED',
  GpsNeverActivated = 'GPS_NEVER_ACTIVATED',
  LocationAvailable = 'LOCATION_AVAILABLE'
}

export type ConsideringSmartChargingStatus = {
  __typename?: 'ConsideringSmartChargingStatus';
  consideration: SmartChargingConsideration;
  externalStart: Scalars['Boolean'];
  type: SmartChargingStatusType;
};

/** Has the same price for all times of the day, week and year */
export type ConstantPrice = {
  __typename?: 'ConstantPrice';
  pricesAndTaxes: PricesAndTaxes;
  rushPricing?: Maybe<Array<Maybe<RushPricingPeriod>>>;
  type: EnergyModelType;
};

export enum CountryCode {
  No = 'NO'
}

export type CreationInfo = {
  __typename?: 'CreationInfo';
  at: Scalars['Date'];
  by: UserInfo;
};

export enum Currency {
  Nok = 'NOK'
}

export type CurrentIntermediaryPayload = {
  __typename?: 'CurrentIntermediaryPayload';
  alertSettings?: Maybe<Array<AlertSetting>>;
};


export type CurrentIntermediaryPayloadAlertSettingsArgs = {
  input?: InputMaybe<IntermediaryAlertSettingsInput>;
};

export type CurrentRangePrice = {
  __typename?: 'CurrentRangePrice';
  /** From, exclusive */
  fromCurrent: Scalars['Int'];
  /** To, inclusive */
  toCurrent: Scalars['Int'];
  /** Price for this current range */
  yearlyPrice: PriceSpecification;
};

export type CustomError = {
  message: Scalars['String'];
  type: ErrorType;
};

export type DateRange = {
  __typename?: 'DateRange';
  /** Day of month and month as a number, including this date */
  from: DayAndMonth;
  /** Day of month and month as a number, excluding this date */
  until: DayAndMonth;
};

export type DayAndMonth = {
  __typename?: 'DayAndMonth';
  day: Scalars['Int'];
  month: Month;
};

/**
 * There may be different prices for day and night, maybe in combination with
 * seasonal (summer/winter) prices. Usually the day is from 6 to 22, but not
 * always.
 */
export type DayAndNightPrices = {
  __typename?: 'DayAndNightPrices';
  day: PricesAndTaxesForPartOfDay;
  night: PricesAndTaxesForPartOfDay;
};

export type DeleteAlertSettingInput = {
  id: Scalars['ID'];
};

export type DeleteAlertSettingPayload = {
  __typename?: 'DeleteAlertSettingPayload';
  success: Scalars['Boolean'];
};

export type DeleteChargingLocationInput = {
  chargingLocationId: Scalars['String'];
};

export type DeleteChargingLocationPayload = {
  __typename?: 'DeleteChargingLocationPayload';
  success: Scalars['Boolean'];
};

export type DeleteMeInput = {
  accessToken: Scalars['String'];
  userId: Scalars['String'];
};

export type DeleteMePayload = {
  __typename?: 'DeleteMePayload';
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type DeliveryChargeCapacityModel = FuseAndVoltageSize | MonthlyPeaksAtDifferentDays | MonthlyPeaksAtDifferentDaysLinearWithBase | WeightedYearlyRollingPeaksInDifferentWeeks;

export type DeliveryChargeEnergyModel = ConstantPrice | DifferentPricesDayAndNight | DifferentPricesSeasonal | DifferentPricesSeasonalDayAndNight | SelectableEnergyModel;

/** Energy taxes (electrical power tax and energy fund tax) */
export type DeliveryChargeEnergyTaxes = {
  __typename?: 'DeliveryChargeEnergyTaxes';
  /** Electrical power tax (Avgift på elektrisk kraft, also called "forbruksavgift") */
  electricalPowerTax: PriceSpecification;
  /** Tax to the energy fund (Enova-avgift) */
  energyFund: PriceSpecification;
};

export type DeliveryChargeModel = {
  __typename?: 'DeliveryChargeModel';
  capacityModel: DeliveryChargeCapacityModel;
  energyModel: DeliveryChargeEnergyModel;
  gln: Scalars['Gln'];
  id: DeliveryChargeModelId;
  taxesApplicable: DeliveryChargeTaxesApplicable;
  timezone: Timezone;
  validFrom: Scalars['Date'];
};

export enum DeliveryChargeModelId {
  AgderEnergiNett = 'AGDER_ENERGI_NETT',
  Alut = 'ALUT',
  AndoyNett = 'ANDOY_NETT',
  Arva = 'ARVA',
  AskerNett = 'ASKER_NETT',
  AustevollKraftlag = 'AUSTEVOLL_KRAFTLAG',
  BarentsNett = 'BARENTS_NETT',
  BindalKraftlag = 'BINDAL_KRAFTLAG',
  BkkNett = 'BKK_NETT',
  BomloKraftnett = 'BOMLO_KRAFTNETT',
  BreheimNett = 'BREHEIM_NETT',
  DeNett = 'DE_NETT',
  Elinett = 'ELINETT',
  Elmea = 'ELMEA',
  Elvenett = 'ELVENETT',
  ElverketHoland = 'ELVERKET_HOLAND',
  Elvia = 'ELVIA',
  Enida = 'ENIDA',
  Everket = 'EVERKET',
  Fagne = 'FAGNE',
  Fjellnett = 'FJELLNETT',
  Foie = 'FOIE',
  Fore = 'FORE',
  GlitreEnergiNett = 'GLITRE_ENERGI_NETT',
  GlitreNett = 'GLITRE_NETT',
  Griug = 'GRIUG',
  HallingdalKraftnett = 'HALLINGDAL_KRAFTNETT',
  HalogalandKraftNett = 'HALOGALAND_KRAFT_NETT',
  Hemsil = 'HEMSIL',
  IndreHordalandKraftnett = 'INDRE_HORDALAND_KRAFTNETT',
  IsaltenNett = 'ISALTEN_NETT',
  JarenEverk = 'JAREN_EVERK',
  KeNett = 'KE_NETT',
  Klive = 'KLIVE',
  KvamEnergiNett = 'KVAM_ENERGI_NETT',
  Kystnett = 'KYSTNETT',
  Lede = 'LEDE',
  LegaNett = 'LEGA_NETT',
  Linea = 'LINEA',
  Linja = 'LINJA',
  Lnett = 'LNETT',
  Lucerna = 'LUCERNA',
  LuostejokNett = 'LUOSTEJOK_NETT',
  Lysna = 'LYSNA',
  Mellom = 'MELLOM',
  MeloyNett = 'MELOY_NETT',
  Midtnett = 'MIDTNETT',
  ModalenKraftlag = 'MODALEN_KRAFTLAG',
  Morenett = 'MORENETT',
  Nettinord = 'NETTINORD',
  Nettselskapet = 'NETTSELSKAPET',
  Nordvestnett = 'NORDVESTNETT',
  NorefjellNett = 'NOREFJELL_NETT',
  Norgesnett = 'NORGESNETT',
  OddaEnergiNett = 'ODDA_ENERGI_NETT',
  RakkestadEnergi = 'RAKKESTAD_ENERGI',
  RkNett = 'RK_NETT',
  RollagElverkNett = 'ROLLAG_ELVERK_NETT',
  Romsdalsnett = 'ROMSDALSNETT',
  RorosEverkNett = 'ROROS_EVERK_NETT',
  SandoyEnergi = 'SANDOY_ENERGI',
  SkiakerNett = 'SKIAKER_NETT',
  SkjakEnergiNett = 'SKJAK_ENERGI_NETT',
  SorAurdalEnergiNett = 'SOR_AURDAL_ENERGI_NETT',
  StangeEnergiNett = 'STANGE_ENERGI_NETT',
  Stannum = 'STANNUM',
  StraumenNett = 'STRAUMEN_NETT',
  Straumnett = 'STRAUMNETT',
  Sunett = 'SUNETT',
  Sygnir = 'SYGNIR',
  SNett = 'S_NETT',
  TelemarkNett = 'TELEMARK_NETT',
  TensioTn = 'TENSIO_TN',
  TensioTs = 'TENSIO_TS',
  TindraNett = 'TINDRA_NETT',
  TrollfjordNett = 'TROLLFJORD_NETT',
  UvdalKraftforsyning = 'UVDAL_KRAFTFORSYNING',
  VangEnergi = 'VANG_ENERGI',
  Vestall = 'VESTALL',
  VestmarNett = 'VESTMAR_NETT',
  VesttelemarkKraftlag = 'VESTTELEMARK_KRAFTLAG',
  Vevig = 'VEVIG',
  Vissi = 'VISSI',
  Vonett = 'VONETT'
}

/** Query delivery charge models using `gln` or our `id` */
export type DeliveryChargeModelInput = {
  date?: InputMaybe<Scalars['Date']>;
  gln?: InputMaybe<Scalars['Gln']>;
  id?: InputMaybe<DeliveryChargeModelId>;
};

export type DeliveryChargeTaxesApplicable = {
  __typename?: 'DeliveryChargeTaxesApplicable';
  electricalPowerTax: Scalars['Boolean'];
  vat: Scalars['Boolean'];
};

export type DeviceFilter = {
  deviceIds?: InputMaybe<Array<Scalars['String']>>;
  recentMeterIds?: InputMaybe<Array<Scalars['String']>>;
};

export type DeviceIdentifierInput = {
  deviceId?: InputMaybe<Scalars['DeviceId']>;
  meterId?: InputMaybe<Scalars['MeterId']>;
};

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  activation?: Maybe<Activation>;
  claimId: Scalars['String'];
  claimedAt?: Maybe<Scalars['String']>;
  claimedBy?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  diagnostic?: Maybe<DiagnosticInfo>;
  intermediaryId?: Maybe<Scalars['String']>;
  metadata?: Maybe<DeviceMetadata>;
  ownedBy?: Maybe<OwnedByUserInfo>;
  permissions: Array<DevicePermission>;
  preferredEnergyBiddingZone?: Maybe<EnergyBiddingZone>;
  recentMeter?: Maybe<RecentMeter>;
  recentMeterId?: Maybe<Scalars['MeterId']>;
  returnInfo?: Maybe<ReturnInfo>;
  shippedAt?: Maybe<Scalars['String']>;
  shippedTo?: Maybe<ShippedToUserInfo>;
  subscription?: Maybe<SubscriptionBilling>;
  type: Scalars['String'];
};

export type DeviceInput = {
  id: Scalars['String'];
  metadata: DeviceMetadataInput;
  type: DeviceType;
};

export type DeviceMetadata = {
  __typename?: 'DeviceMetadata';
  chip: Scalars['String'];
  espIdfVersionFactoryPartition: Scalars['String'];
  firmwareTheme: Scalars['String'];
  firmwareVersionFactoryPartition: Scalars['String'];
  iccid?: Maybe<Scalars['String']>;
  imei?: Maybe<Scalars['String']>;
  memoryPartitioning: Scalars['String'];
  modemFirmwareVersion?: Maybe<Scalars['String']>;
  productBatch: Scalars['String'];
};

export type DeviceMetadataInput = {
  chip: Scalars['String'];
  espIdfVersionFactoryPartition: Scalars['String'];
  firmwareTheme: Scalars['String'];
  firmwareVersionFactoryPartition: Scalars['String'];
  iccid?: InputMaybe<Scalars['String']>;
  imei?: InputMaybe<Scalars['String']>;
  memoryPartitioning: Scalars['String'];
  modemFirmwareVersion?: InputMaybe<Scalars['String']>;
  productBatch: Scalars['String'];
};

export type DeviceOwnerInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  externalUserId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalAddress?: InputMaybe<OptionalFieldsPostalAddressInput>;
};

export type DevicePermission = {
  __typename?: 'DevicePermission';
  permittedEmail: Scalars['String'];
  permittedUserId: Scalars['String'];
};

export type DeviceQueryIdentifier = {
  claimId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
};

export type DeviceQueryInput = {
  identifier: DeviceQueryIdentifier;
};

export type DeviceQueryPayload = {
  __typename?: 'DeviceQueryPayload';
  device: DeviceInfo;
};

export type DeviceStats = {
  __typename?: 'DeviceStats';
  devicesActivated: Scalars['Int'];
  devicesActivationReady: Scalars['Int'];
  devicesAtCustomer: Scalars['Int'];
  devicesDeactivated: Scalars['Int'];
  returnedDevices: Scalars['Int'];
  total: Scalars['Int'];
};

export enum DeviceType {
  HanLtem = 'HAN_LTEM',
  HanWifi = 'HAN_WIFI'
}

/** Diagnostic about network state and reboots */
export type DiagnosticInfo = {
  __typename?: 'DiagnosticInfo';
  firmwareVersion?: Maybe<Scalars['Int']>;
  lastMessageAt?: Maybe<Scalars['Date']>;
  lastPingAt?: Maybe<Scalars['Date']>;
  reboots?: Maybe<Scalars['Int']>;
  signalStrength?: Maybe<Scalars['Int']>;
};

/**
 * Has different prices for day and night, may also have different prices for
 * weekends and holidays
 */
export type DifferentPricesDayAndNight = {
  __typename?: 'DifferentPricesDayAndNight';
  holidayPricesFollowNightPrices: Scalars['Boolean'];
  prices: DayAndNightPrices;
  type: EnergyModelType;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
};

/** Has different prices for summer and winter */
export type DifferentPricesSeasonal = {
  __typename?: 'DifferentPricesSeasonal';
  seasons: SeasonalPricesWithoutDayAndNight;
  type: EnergyModelType;
};

/**
 * Has different prices for summer and winter, day and night, may also have
 * different prices for weekends and holidays
 */
export type DifferentPricesSeasonalDayAndNight = {
  __typename?: 'DifferentPricesSeasonalDayAndNight';
  holidayPricesFollowNightPrices: Scalars['Boolean'];
  seasons: SeasonalPrices;
  type: EnergyModelType;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
};

export type DisabledSmartChargingStatus = {
  __typename?: 'DisabledSmartChargingStatus';
  externalStart: Scalars['Boolean'];
  type: SmartChargingStatusType;
};

export type DisconnectVendorInput = {
  vendor: IntegrationVendor;
};

export type DisconnectVendorPayload = {
  __typename?: 'DisconnectVendorPayload';
  success: Scalars['Boolean'];
};

export type EmailActionInput = {
  email: Scalars['String'];
  redirectUri?: InputMaybe<Scalars['String']>;
};

export type EmailIdentity = IdentityBase & {
  __typename?: 'EmailIdentity';
  email: Scalars['String'];
  type: IdentityType;
};

export type EmailIdentityInput = {
  email: Scalars['String'];
};

export type EndUserAlertSettingCreator = {
  __typename?: 'EndUserAlertSettingCreator';
  type: AlertSettingCreatorType;
  userId: Scalars['ID'];
};

/** Energy bidding zones, as reported by the _European Network of Transmission System Operators for Electricity_ (ENTSO-E). */
export enum EnergyBiddingZone {
  /** Norway: south east */
  No1 = 'NO1',
  /** Norway: south */
  No2 = 'NO2',
  /** Norway: mid */
  No3 = 'NO3',
  /** Norway: north */
  No4 = 'NO4',
  /** Norway: west */
  No5 = 'NO5',
  Unrecognized = 'UNRECOGNIZED'
}

export enum EnergyModelType {
  ConstantPrice = 'ConstantPrice',
  DifferentPricesDayAndNight = 'DifferentPricesDayAndNight',
  DifferentPricesSeasonal = 'DifferentPricesSeasonal',
  DifferentPricesSeasonalDayAndNight = 'DifferentPricesSeasonalDayAndNight',
  SelectableEnergyModel = 'SelectableEnergyModel'
}

export type EnergyPriceOfHour = {
  __typename?: 'EnergyPriceOfHour';
  from?: Maybe<Scalars['Date']>;
  priceSpecification?: Maybe<PriceSpecification>;
  to?: Maybe<Scalars['Date']>;
};

/**
 * Energy range is inclusive the from and to fields. A normal range is from 0 to
 * 1999 Wh. The granularity of our data is in Wh.
 */
export type EnergyRangeInWh = {
  __typename?: 'EnergyRangeInWh';
  /** From, inclusive */
  from: Scalars['Int'];
  /** To, inclusive */
  to: Scalars['Int'];
};

export type Error = UserNotAuthenticatedError | UserNotPermittedError;

export enum ErrorType {
  UserNotAuthenticatedError = 'UserNotAuthenticatedError',
  UserNotPermittedError = 'UserNotPermittedError'
}

export type EstimatedHourlyActiveEnergyLimitWarningAlertSetting = {
  __typename?: 'EstimatedHourlyActiveEnergyLimitWarningAlertSetting';
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  limitInWattHours: Scalars['Int'];
  notificationChannels: Array<NotificationChannel>;
};

export type ExternalUserIdentifier = {
  __typename?: 'ExternalUserIdentifier';
  externalId: Scalars['String'];
};

export type ExternalUserIdentifierInput = {
  externalId: Scalars['String'];
};

export type ExternalUserIdentity = IdentityBase & {
  __typename?: 'ExternalUserIdentity';
  externalId: Scalars['String'];
  intermediaryId: Scalars['String'];
  type: IdentityType;
};

/**
 * Capacity model where grid voltage and/or fuse size make the capacity steps.
 *
 * Capacity model used by Alut, NettiNord, SuNett.
 */
export type FuseAndVoltageSize = {
  __typename?: 'FuseAndVoltageSize';
  /** Prices for one phase networks */
  onePhasePrices: PhasePrices;
  /** Prices for three phase networks */
  threePhasePrices: PhasePrices;
  type: CapacityModelType;
};

export type GpsDisabledConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'GpsDisabledConfiguredIntegrationVendorVehicleLocationState';
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState';
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type GrantDeviceAccessInput = {
  deviceId: Scalars['String'];
  permittedIdentity: EmailIdentityInput;
};

export type HourRange = {
  __typename?: 'HourRange';
  /** 0-23, including start */
  from: Scalars['Int'];
  /** 0-23, excluding end */
  until: Scalars['Int'];
};

export type HourlyConsumptionLimitEstimationWarningAlertSetting = {
  __typename?: 'HourlyConsumptionLimitEstimationWarningAlertSetting';
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  limitInWatts: Scalars['Int'];
  notificationChannels: Array<NotificationChannel>;
};

export type Identity = EmailIdentity | ExternalUserIdentity | IntermediaryApiKeyIdentity | InternalIdentity;

export type IdentityBase = {
  type: IdentityType;
};

export enum IdentityType {
  EmailIdentity = 'EmailIdentity',
  ExternalUserIdentity = 'ExternalUserIdentity',
  IntermediaryApiKeyIdentity = 'IntermediaryApiKeyIdentity',
  InternalIdentity = 'InternalIdentity'
}

export type IntegrationAuthorizationUrlInput = {
  vendor: IntegrationVendor;
};

export type IntegrationAuthorizationUrlResponse = {
  __typename?: 'IntegrationAuthorizationUrlResponse';
  authorizationUrl: Scalars['String'];
};

export enum IntegrationVendor {
  Audi = 'AUDI',
  Bmw = 'BMW',
  Cupra = 'CUPRA',
  Ford = 'FORD',
  Jaguar = 'JAGUAR',
  Mini = 'MINI',
  Porsche = 'PORSCHE',
  Renault = 'RENAULT',
  Seat = 'SEAT',
  Skoda = 'SKODA',
  Tesla = 'TESLA',
  Volkswagen = 'VOLKSWAGEN',
  Volvo = 'VOLVO'
}

export type IntegrationVendorInfo = ConfiguredIntegrationVendorInfo | NotConfiguredIntegrationVendorInfo;

export enum IntegrationVendorStatus {
  Configured = 'CONFIGURED',
  NotConfigured = 'NOT_CONFIGURED'
}

export type IntermediaryAlertSettingCreator = {
  __typename?: 'IntermediaryAlertSettingCreator';
  intermediaryId: Scalars['String'];
  type: AlertSettingCreatorType;
};

export type IntermediaryAlertSettingsFilter = {
  deviceIds?: InputMaybe<Array<Scalars['String']>>;
};

export type IntermediaryAlertSettingsInput = {
  filter?: InputMaybe<IntermediaryAlertSettingsFilter>;
};

/** Intermediary api keys are authorized at the intermediate level, to add or remove users on behalf of the intermediary. */
export type IntermediaryApiKey = {
  __typename?: 'IntermediaryApiKey';
  apiKey?: Maybe<Scalars['String']>;
  created: CreationInfo;
  id: Scalars['ID'];
  intermediaryId: Scalars['String'];
  name: Scalars['String'];
  secret?: Maybe<Scalars['String']>;
  startOfSecret: Scalars['String'];
  /** @deprecated Use `apiKey` */
  token?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type IntermediaryApiKeyIdentity = IdentityBase & {
  __typename?: 'IntermediaryApiKeyIdentity';
  apiKeyHash: Scalars['String'];
  type: IdentityType;
};

export type IntermediaryApiKeyPayload = {
  __typename?: 'IntermediaryApiKeyPayload';
  intermediaryApiKey: IntermediaryApiKey;
};

export type IntermediaryApiKeys = {
  __typename?: 'IntermediaryApiKeys';
  keys: Array<IntermediaryApiKey>;
};

export type IntermediaryApiKeysResult = IntermediaryApiKeys | UserNotPermittedError;

/** An intermediary is the entity or organization that has support responsibility for the devices. */
export type IntermediaryInfo = {
  __typename?: 'IntermediaryInfo';
  apiKeys?: Maybe<IntermediaryApiKeysResult>;
  deviceStats?: Maybe<DeviceStats>;
  devices?: Maybe<Array<DeviceInfo>>;
  id: Scalars['ID'];
  permissionScopes?: Maybe<Array<Scalars['String']>>;
};


/** An intermediary is the entity or organization that has support responsibility for the devices. */
export type IntermediaryInfoDevicesArgs = {
  search?: InputMaybe<Search>;
};

export enum IntermediaryUserScope {
  ReadBufferedMeasurements = 'READ_BUFFERED_MEASUREMENTS',
  ReadDeviceInfo = 'READ_DEVICE_INFO',
  ReadHourlyReports = 'READ_HOURLY_REPORTS',
  ReadMeasurementStream = 'READ_MEASUREMENT_STREAM'
}

export type IntermediaryUserTokenPayload = {
  __typename?: 'IntermediaryUserTokenPayload';
  accessToken: Scalars['String'];
  user: UserInfo;
};

export type IntermediaryWebhookNotificationChannel = {
  __typename?: 'IntermediaryWebhookNotificationChannel';
  callbackUrl: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  type: NotificationChannelType;
};

export type IntermediaryWebhookSetting = {
  __typename?: 'IntermediaryWebhookSetting';
  id: Scalars['String'];
};

export type InternalIdentity = IdentityBase & {
  __typename?: 'InternalIdentity';
  id: Scalars['String'];
  type: IdentityType;
};

export enum Interval {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY'
}

export type LocationAvailableConfiguredIntegrationVendorVehicleLocationState = {
  __typename?: 'LocationAvailableConfiguredIntegrationVendorVehicleLocationState';
  lastUpdatedAt: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  session: SessionInfo;
  user: UserInfo;
};

export type Measurement = {
  __typename?: 'Measurement';
  /** Active energy exported, in Wh */
  activeEnergyExported?: Maybe<Scalars['Float']>;
  /** Active energy imported, in Wh */
  activeEnergyImported?: Maybe<Scalars['Float']>;
  /** Active power export, in W */
  activePowerExport?: Maybe<Scalars['Float']>;
  /** Active power import, in W */
  activePowerImport?: Maybe<Scalars['Float']>;
  /** Current, phase 1, in A */
  currentPhase1?: Maybe<Scalars['Float']>;
  /** Current, phase 2, in A */
  currentPhase2?: Maybe<Scalars['Float']>;
  /** Current, phase 3, in A */
  currentPhase3?: Maybe<Scalars['Float']>;
  /** Reactive energy exported, in varh */
  reactiveEnergyExported?: Maybe<Scalars['Float']>;
  /** Reactive energy imported, in varh */
  reactiveEnergyImported?: Maybe<Scalars['Float']>;
  /** Reactive power export, in var */
  reactivePowerExport?: Maybe<Scalars['Float']>;
  /** Reactive power import, in var */
  reactivePowerImport?: Maybe<Scalars['Float']>;
  /** Time in UTC */
  time: Scalars['Date'];
  /** Voltage, phase 1, in V */
  voltagePhase1?: Maybe<Scalars['Float']>;
  /** Voltage, phase 2, in V */
  voltagePhase2?: Maybe<Scalars['Float']>;
  /** Voltage, phase 3, in V */
  voltagePhase3?: Maybe<Scalars['Float']>;
};

/** Metadata about our services */
export type Metadata = {
  __typename?: 'Metadata';
  /** Required version to run the app. The app should block the user from using an older version than this. */
  requiredAppVersion: Scalars['String'];
  /** Suggested version to run the app. The app will present the user with information about expecting errors when running older versions of the app. */
  suggestedAppVersion: Scalars['String'];
};

export enum MeterProducer {
  Aidon = 'AIDON',
  Embriq = 'EMBRIQ',
  Kaifa = 'KAIFA',
  Kamstrup = 'KAMSTRUP',
  Unrecognized = 'UNRECOGNIZED'
}

export type MillDevice = {
  __typename?: 'MillDevice';
  currentTemperature: Scalars['Float'];
  id: Scalars['Int'];
  isOn: Scalars['Boolean'];
  name: Scalars['String'];
};

export enum Month {
  April = 'APRIL',
  August = 'AUGUST',
  December = 'DECEMBER',
  February = 'FEBRUARY',
  January = 'JANUARY',
  July = 'JULY',
  June = 'JUNE',
  March = 'MARCH',
  May = 'MAY',
  November = 'NOVEMBER',
  October = 'OCTOBER',
  September = 'SEPTEMBER'
}

export type MonthRange = {
  __typename?: 'MonthRange';
  /** 1-12, including start */
  from: Scalars['Int'];
  /** 1-12, exluding end */
  until: Scalars['Int'];
};

export type MonthWeights = {
  __typename?: 'MonthWeights';
  april: Scalars['Float'];
  august: Scalars['Float'];
  december: Scalars['Float'];
  february: Scalars['Float'];
  january: Scalars['Float'];
  july: Scalars['Float'];
  june: Scalars['Float'];
  march: Scalars['Float'];
  may: Scalars['Float'];
  november: Scalars['Float'];
  october: Scalars['Float'];
  september: Scalars['Float'];
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and used to
 * determine the capacity step. The steps has a range in Wh with a price, and
 * there is also a field defining the price for when all capacity steps are
 * exceeded.
 */
export type MonthlyPeaksAtDifferentDays = {
  __typename?: 'MonthlyPeaksAtDifferentDays';
  capacitySteps: Array<CapacityStep>;
  /**
   * The number of daily peaks that will be averaged to find the capacity step.
   * This is normally three, but there are a few cases using 1.
   */
  peaksPerMonth: Scalars['Int'];
  /**
   * Normally, there is an upper limit, above which there is a costly price.
   * Sometimes, the grid companies references a list of commercial capacity steps
   * when the consumtion is high, but we assume the customers are below the extra
   * commercial steps and "wrongly" set the highest of the private capacity step
   * prices, for these few cases.
   */
  priceAboveCapacitySteps: PriceSpecification;
  type: CapacityModelType;
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and
 * multiplied by `pricePerKwhForAverageOfPeaks` and added to `baseFeePerYear`.
 *
 * Capacity model used by SkiakerNett, but with only one maxima.
 *
 * <https://skiakernett.no/nettleige/>
 */
export type MonthlyPeaksAtDifferentDaysLinearWithBase = {
  __typename?: 'MonthlyPeaksAtDifferentDaysLinearWithBase';
  baseFeePerYear: PriceSpecification;
  /** The number of daily peaks that will be averaged to find the capacity step. */
  peaksPerMonth: Scalars['Int'];
  pricePerKwhForAverageOfPeaks: PriceSpecification;
  type: CapacityModelType;
};

export type Mutation = {
  __typename?: 'Mutation';
  addChargingLocation: AddChargingLocationPayload;
  addChargingPermission?: Maybe<AddChargingPermissionPayload>;
  addDevicePermissions?: Maybe<AddDevicePermissionsPayload>;
  addDeviceToken?: Maybe<Scalars['Void']>;
  addEstimatedHourlyActiveEnergyLimitWarningAlertSetting: EstimatedHourlyActiveEnergyLimitWarningAlertSetting;
  addHourlyConsumptionLimitEstimationWarningAlertSetting: HourlyConsumptionLimitEstimationWarningAlertSetting;
  addIntermediaryApiKey: IntermediaryApiKeyPayload;
  addIntermediaryWebhookNotificationChannelToAlertSetting: AddIntermediaryWebhookNotificationChannelToAlertSettingPayload;
  addPriceWarningAlertSetting: PriceWarningAlertSetting;
  addPushNotificationChannelToAlertSetting: AddPushNotificationChannelToAlertSettingPayload;
  addUser: AddUserPayload;
  addUserSession: AddUserSessionPayload;
  claimDevice: DeviceInfo;
  createDevice: DeviceInfo;
  deleteAlertSetting: DeleteAlertSettingPayload;
  deleteChargingLocation: DeleteChargingLocationPayload;
  deleteDeviceName?: Maybe<DeviceInfo>;
  deleteMe: DeleteMePayload;
  disconnectVendor: DisconnectVendorPayload;
  grantDeviceAccess: Permission;
  login: LoginPayload;
  loginWithMillCredentials?: Maybe<Scalars['Boolean']>;
  orderDevice: OrderDevicePayload;
  register?: Maybe<Scalars['Boolean']>;
  removeChargingPermission?: Maybe<RemoveChargingPermissionPayload>;
  removeDevicePermissions?: Maybe<RemoveDevicePermissionsPayload>;
  removeDeviceToken?: Maybe<Scalars['Void']>;
  removeIntermediaryApiKey: IntermediaryApiKeyPayload;
  removeNotificationChannelFromAlertSetting: RemoveNotificationChannelFromAlertSettingPayload;
  removeUser: RemoveUserPayload;
  returnDevice: ReturnDevicePayload;
  revokeDeviceAccess?: Maybe<Scalars['Boolean']>;
  sendEmailVerificationEmail?: Maybe<Scalars['Boolean']>;
  sendResetPasswordEmail?: Maybe<Scalars['Boolean']>;
  setDeviceActivationStatus: SetDeviceActivationStatusPayload;
  setIntermediaryWebhookSetting: SetIntermediaryWebhookSettingPayload;
  shipDevice: DeviceInfo;
  testWebhookSetting: TestWebhookSettingPayload;
  updateChargingLocation: UpdateChargingLocationPayload;
  updateDeviceName?: Maybe<DeviceInfo>;
  updateDeviceOwner?: Maybe<DeviceInfo>;
  updateEstimatedHourlyActiveEnergyLimitWarningAlertSetting: UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload;
  updateHourlyConsumptionLimitEstimationWarningAlertSetting: UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload;
  updateMillDevice?: Maybe<Scalars['Boolean']>;
  updatePreferredEnergyBiddingZone?: Maybe<Scalars['Boolean']>;
  updateSmartChargingPolicy: UpdateSmartChargingPolicyPayload;
};


export type MutationAddChargingLocationArgs = {
  input: AddChargingLocationInput;
};


export type MutationAddChargingPermissionArgs = {
  input: AddChargingPermissionInput;
};


export type MutationAddDevicePermissionsArgs = {
  input: AddDevicePermissionsInput;
};


export type MutationAddDeviceTokenArgs = {
  deviceToken: Scalars['String'];
};


export type MutationAddEstimatedHourlyActiveEnergyLimitWarningAlertSettingArgs = {
  input: AddEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput;
};


export type MutationAddHourlyConsumptionLimitEstimationWarningAlertSettingArgs = {
  input: AddHourlyConsumptionLimitEstimationWarningAlertSettingInput;
};


export type MutationAddIntermediaryApiKeyArgs = {
  input: AddIntermediaryApiKeyInput;
};


export type MutationAddIntermediaryWebhookNotificationChannelToAlertSettingArgs = {
  input: AddIntermediaryWebhookNotificationChannelToAlertSettingInput;
};


export type MutationAddPriceWarningAlertSettingArgs = {
  input: AddPriceWarningAlertSettingInput;
};


export type MutationAddPushNotificationChannelToAlertSettingArgs = {
  input: AddPushNotificationChannelToAlertSettingInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationAddUserSessionArgs = {
  input: AddUserSessionInput;
};


export type MutationClaimDeviceArgs = {
  claimId: Scalars['ClaimId'];
};


export type MutationCreateDeviceArgs = {
  input: DeviceInput;
};


export type MutationDeleteAlertSettingArgs = {
  input: DeleteAlertSettingInput;
};


export type MutationDeleteChargingLocationArgs = {
  input: DeleteChargingLocationInput;
};


export type MutationDeleteDeviceNameArgs = {
  deviceId: Scalars['String'];
};


export type MutationDeleteMeArgs = {
  input: DeleteMeInput;
};


export type MutationDisconnectVendorArgs = {
  input: DisconnectVendorInput;
};


export type MutationGrantDeviceAccessArgs = {
  input: GrantDeviceAccessInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginWithMillCredentialsArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationOrderDeviceArgs = {
  input: OrderDeviceInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationRemoveChargingPermissionArgs = {
  input: RemoveChargingPermissionInput;
};


export type MutationRemoveDevicePermissionsArgs = {
  input: RemoveDevicePermissionsInput;
};


export type MutationRemoveDeviceTokenArgs = {
  deviceToken: Scalars['String'];
};


export type MutationRemoveIntermediaryApiKeyArgs = {
  input: RemoveIntermediaryApiKeyInput;
};


export type MutationRemoveNotificationChannelFromAlertSettingArgs = {
  input: RemoveNotificationChannelFromAlertSettingInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationReturnDeviceArgs = {
  input: ReturnDeviceInput;
};


export type MutationRevokeDeviceAccessArgs = {
  input: RevokeDeviceAccessInput;
};


export type MutationSendEmailVerificationEmailArgs = {
  input: EmailActionInput;
};


export type MutationSendResetPasswordEmailArgs = {
  input: EmailActionInput;
};


export type MutationSetDeviceActivationStatusArgs = {
  input: SetDeviceActivationStatusInput;
};


export type MutationSetIntermediaryWebhookSettingArgs = {
  input: SetIntermediaryWebhookSettingInput;
};


export type MutationShipDeviceArgs = {
  deviceIdentifier: ShipDeviceIdentifierInput;
  intermediaryId: Scalars['String'];
  shipTo?: InputMaybe<ShipToUserInput>;
  subscription: SubscriptionInput;
};


export type MutationTestWebhookSettingArgs = {
  input: TestWebhookSettingInput;
};


export type MutationUpdateChargingLocationArgs = {
  input: UpdateChargingLocationInput;
};


export type MutationUpdateDeviceNameArgs = {
  deviceId: Scalars['String'];
  deviceName: Scalars['String'];
};


export type MutationUpdateDeviceOwnerArgs = {
  deviceId: Scalars['String'];
  deviceOwner: DeviceOwnerInput;
};


export type MutationUpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingArgs = {
  input: UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput;
};


export type MutationUpdateHourlyConsumptionLimitEstimationWarningAlertSettingArgs = {
  input: UpdateHourlyConsumptionLimitEstimationWarningAlertSettingInput;
};


export type MutationUpdateMillDeviceArgs = {
  deviceId: Scalars['Int'];
  isOn: Scalars['Boolean'];
};


export type MutationUpdatePreferredEnergyBiddingZoneArgs = {
  deviceId: Scalars['String'];
  preferredEnergyBiddingZone: EnergyBiddingZone;
};


export type MutationUpdateSmartChargingPolicyArgs = {
  input: UpdateSmartChargingPolicyInput;
};

export type MyDevicesInput = {
  filter?: InputMaybe<DeviceFilter>;
};

export type NotConfiguredIntegrationVendorInfo = {
  __typename?: 'NotConfiguredIntegrationVendorInfo';
  status: IntegrationVendorStatus;
  vendor: IntegrationVendor;
};

export type NotificationChannel = IntermediaryWebhookNotificationChannel | PushNotificationChannel;

export enum NotificationChannelType {
  IntermediaryWebhook = 'INTERMEDIARY_WEBHOOK',
  PushNotification = 'PUSH_NOTIFICATION'
}

export enum NotificationType {
  PriceWarning = 'PRICE_WARNING'
}

export type OptionalFieldsPostalAddress = {
  __typename?: 'OptionalFieldsPostalAddress';
  country?: Maybe<PostcodeCountry>;
  postcode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
};

export type OptionalFieldsPostalAddressInput = {
  country?: InputMaybe<PostcodeCountry>;
  postcode?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  intermediaryId: Scalars['String'];
  orderId: Scalars['String'];
  orderStatus: OrderStatus;
  recipient: PostalRecipient;
  subscriptionType: SubscriptionType;
  type: DeviceType;
  updatedAt: Scalars['Date'];
};

export type OrderDeviceInput = {
  recipient: PostalRecipientInput;
  subscriptionType: SubscriptionType;
  type: DeviceType;
};

export type OrderDevicePayload = {
  __typename?: 'OrderDevicePayload';
  order: Order;
  ordersForUser: Array<Maybe<Order>>;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Ordered = 'ORDERED',
  Shipped = 'SHIPPED'
}

export type OrdersForUserInput = {
  externalUserId: Scalars['String'];
};

export type OrdersForUserPayload = {
  __typename?: 'OrdersForUserPayload';
  orders: Array<Maybe<Order>>;
};

/** User information for device owners */
export type OwnedByUserInfo = {
  __typename?: 'OwnedByUserInfo';
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<OptionalFieldsPostalAddress>;
};

/** Our permission system works across different types of resources and different types of actors */
export type Permission = {
  __typename?: 'Permission';
  /** The id of the entity being permitted, e.g. a userId */
  entityId: Scalars['String'];
  /** The id of the resource the permission is targeting, e.g. a deviceId */
  resourceId: Scalars['String'];
  /** Permissions to resources, like read or write, are defined by scopes */
  scopes: Array<Scalars['String']>;
};

export type PhasePrices = {
  __typename?: 'PhasePrices';
  /** Prices for 230V networks */
  voltage230: PhaseVoltagePrices;
  /** Prices for 400V networks */
  voltage400: PhaseVoltagePrices;
};

export type PhaseVoltagePrices = {
  __typename?: 'PhaseVoltagePrices';
  currentRangePrices: Array<CurrentRangePrice>;
  yearlyPriceAboveCurrentRanges: PriceSpecification;
};

export type PlannedSmartChargingStatus = {
  __typename?: 'PlannedSmartChargingStatus';
  externalStart: Scalars['Boolean'];
  plan: SmartChargingPlan;
  planningStatus: SmartChargingPlanningStatusType;
  type: SmartChargingStatusType;
};

export type PostalAddress = {
  __typename?: 'PostalAddress';
  country: PostcodeCountry;
  postcode: Scalars['String'];
  streetAddress: Scalars['String'];
};

export type PostalAddressInput = {
  country: PostcodeCountry;
  postcode: Scalars['String'];
  streetAddress: Scalars['String'];
};

export type PostalRecipient = {
  __typename?: 'PostalRecipient';
  email: Scalars['String'];
  externalUserId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalAddress: PostalRecipientPostalAddress;
};

export type PostalRecipientInput = {
  /** Max-length 60 characters */
  email: Scalars['String'];
  externalUserId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalAddress: PostalRecipientPostalAddressInput;
};

export type PostalRecipientPostalAddress = {
  __typename?: 'PostalRecipientPostalAddress';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: CountryCode;
  postalcode: Scalars['String'];
};

export type PostalRecipientPostalAddressInput = {
  /** Max-length 35 characters */
  addressLine1: Scalars['String'];
  /** Max-length 35 characters */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** Max-length 35 characters */
  city: Scalars['String'];
  country: CountryCode;
  /** 4 digits if country is Norway */
  postalcode: Scalars['String'];
};

export enum PostcodeCountry {
  Norway = 'NORWAY'
}

export type PriceSpecification = {
  __typename?: 'PriceSpecification';
  /** The actual price, this should be including VAT (MVA) where it applies */
  price: Scalars['Float'];
  /** The currency of the given price, usually in NOK */
  priceCurrency: Currency;
  /** The prices without VAT (MVA) */
  priceExcludingVat: Scalars['Float'];
  /** VAT, or "Merverdiavgift" */
  vat: Scalars['Float'];
};

export type PriceWarningAlertSetting = {
  __typename?: 'PriceWarningAlertSetting';
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  energyBiddingZone: EnergyBiddingZone;
  id: Scalars['ID'];
  notificationChannels: Array<NotificationChannel>;
};

export type PriceWarningPushNotificationSetting = {
  __typename?: 'PriceWarningPushNotificationSetting';
  deviceId: Scalars['String'];
  notificationType: NotificationType;
  wantsNotification: Scalars['Boolean'];
};

export type PricesAndTaxes = {
  __typename?: 'PricesAndTaxes';
  priceExcludingEnergyTaxes: PriceSpecification;
  priceIncludingEnergyTaxes: PriceSpecification;
  taxes: DeliveryChargeEnergyTaxes;
};

/**
 * Price and tax info for part of a day, includes a specification of which hours
 * the price is for.
 */
export type PricesAndTaxesForPartOfDay = {
  __typename?: 'PricesAndTaxesForPartOfDay';
  hours: HourRange;
  pricesAndTaxes: PricesAndTaxes;
};

/**
 * The prices for a part of year, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYear = {
  __typename?: 'PricesForPartOfYear';
  months: MonthRange;
  prices: DayAndNightPrices;
};

/**
 * The prices for a part of year without differentiating day and night, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYearWithoutDayAndNight = {
  __typename?: 'PricesForPartOfYearWithoutDayAndNight';
  months: MonthRange;
  /** @deprecated The field is not complete, use the adjacent field `pricesAndTaxes` */
  price: PriceSpecification;
  pricesAndTaxes: PricesAndTaxes;
};

export type PushNotificationChannel = {
  __typename?: 'PushNotificationChannel';
  forUserWithId: Scalars['String'];
  type: NotificationChannelType;
};

export type PushNotificationSetting = PriceWarningPushNotificationSetting;

export type Query = {
  __typename?: 'Query';
  chargingStatistics: ChargingStatisticsPayload;
  currentIntermediary: CurrentIntermediaryPayload;
  /**
   * Day ahead prices
   *
   * Get price data from the Entso-E transparency platrom for the time range
   * specified.
   *
   * Prices are converted to NOK and VAT is added for the zones where
   * this is applicable.
   *
   * Read more about the transparency platform of the European Network of
   * Transmission System Operators at https://transparency.entsoe.eu/
   */
  dayAheadPrices: Array<Maybe<EnergyPriceOfHour>>;
  deliveryChargeModel: DeliveryChargeModel;
  device: DeviceQueryPayload;
  /** Generates an authorization URL which can be embedded in a webview which in turn will allow an end user to grant our API access to control their vehicle/charger */
  integrationAuthorizationUrl: IntegrationAuthorizationUrlResponse;
  me: UserInfo;
  /**
   * Get data from the latest measurements using deviceId or meterId (or both)
   *
   * * If only deviceId is given, reports for the most recent meterId that the device was connected to in the requested range will be returned
   * * If only meterId is given, reports for this meter will be returned from the most recent deviceId that was connected to this meter in the requested range
   * * If both meterId and deviceId is given, reports will only be returned if the given deviceId was connected to the given meterId in the requested range
   *
   * In all cases the accessToken needs permissions to read reports for the corresponding deviceId.
   *
   * Measurements are only available for two to three hours.
   */
  measurements: Array<Measurement>;
  /** Get metadata about services, like minimum required app requiredAppVersion */
  metadata: Metadata;
  /** Returns the Mill devices associated with the current session */
  millDevices: Array<MillDevice>;
  myDevices: Array<DeviceInfo>;
  ordersForUser: OrdersForUserPayload;
  /** Get permissions containing requested scopes for the authenticated user */
  permissions: Array<Permission>;
  /**
   * Get hourly or daily reports using deviceId or meterId (or both)
   *
   * * If only deviceId is given, reports for the most recent meterId that the device was connected to in the requested range will be returned
   * * If only meterId is given, reports for this meter will be returned from the most recent deviceId that was connected to this meter in the requested range
   * * If both meterId and deviceId is given, reports will only be returned if the given deviceId was connected to the given meterId in the requested range
   *
   * In all cases the accessToken needs permissions to read reports for the corresponding deviceId.
   */
  reports: Array<Report>;
  user: UserPayload;
  vehicleSmartChargingStatus: VehicleSmartChargingStatusPayload;
};


export type QueryChargingStatisticsArgs = {
  input: ChargingStatisticsInput;
};


export type QueryDayAheadPricesArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  zone: EnergyBiddingZone;
};


export type QueryDeliveryChargeModelArgs = {
  input: DeliveryChargeModelInput;
};


export type QueryDeviceArgs = {
  input: DeviceQueryInput;
};


export type QueryIntegrationAuthorizationUrlArgs = {
  input: IntegrationAuthorizationUrlInput;
};


export type QueryMeasurementsArgs = {
  deviceId?: InputMaybe<Scalars['String']>;
  from: Scalars['String'];
  meterId?: InputMaybe<Scalars['String']>;
  to: Scalars['String'];
};


export type QueryMyDevicesArgs = {
  input?: InputMaybe<MyDevicesInput>;
};


export type QueryOrdersForUserArgs = {
  input: OrdersForUserInput;
};


export type QueryPermissionsArgs = {
  scopes: Array<Scalars['String']>;
};


export type QueryReportsArgs = {
  deviceId?: InputMaybe<Scalars['String']>;
  from: Scalars['String'];
  interval: Interval;
  meterId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Timezone>;
  to: Scalars['String'];
};


export type QueryUserArgs = {
  input: UserInput;
};


export type QueryVehicleSmartChargingStatusArgs = {
  input: VehicleSmartChargingStatusInput;
};

export type Range = {
  __typename?: 'Range';
  max: Scalars['Float'];
  mean: Scalars['Float'];
  min: Scalars['Float'];
};

export type RecentMeter = {
  __typename?: 'RecentMeter';
  firstSeenAt: Scalars['Date'];
  meterId: Scalars['MeterId'];
  producer: MeterProducer;
  shortMeterId: Scalars['String'];
};

export type RegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  redirectUri?: InputMaybe<Scalars['String']>;
};

export type RemoveChargingPermissionInput = {
  userIdentifier: UserIdentifierInput;
};

export type RemoveChargingPermissionPayload = {
  __typename?: 'RemoveChargingPermissionPayload';
  modifiedPermission: Permission;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type RemoveDevicePermissionsInput = {
  deviceIdentifiers: Array<DeviceIdentifierInput>;
  userIdentifier: UserIdentifierInput;
};

export type RemoveDevicePermissionsPayload = {
  __typename?: 'RemoveDevicePermissionsPayload';
  modifiedPermissions: Array<Permission>;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type RemoveIntermediaryApiKeyInput = {
  id: Scalars['String'];
};

export type RemoveIntermediaryUserInput = {
  externalId: Scalars['String'];
};

export type RemoveIntermediaryUserPayload = {
  __typename?: 'RemoveIntermediaryUserPayload';
  user: UserInfo;
};

export type RemoveNotificationChannelFromAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  notificationChannelType: NotificationChannelType;
};

export type RemoveNotificationChannelFromAlertSettingPayload = {
  __typename?: 'RemoveNotificationChannelFromAlertSettingPayload';
  success: Scalars['Boolean'];
};

export type RemoveUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type Report = {
  __typename?: 'Report';
  /**
   * Active energy exported in the time period, in Wh
   *
   * Not available for the _current hour_
   */
  activeEnergyExported?: Maybe<Scalars['Float']>;
  /**
   * Active energy imported in the time period, in Wh
   *
   * Not available for the _current hour_
   */
  activeEnergyImported?: Maybe<Scalars['Float']>;
  /** Average of ActivePowerExport values in the time period, in W */
  averageActivePowerExport?: Maybe<Scalars['Float']>;
  /** Average of ActivePowerImport values in the time period, in W */
  averageActivePowerImport?: Maybe<Scalars['Float']>;
  /** Cumulative values of the active energy export in Wh at the start of the interval */
  cumulativeActiveEnergyExportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the active energy import in Wh at the start of the interval */
  cumulativeActiveEnergyImportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the reactive energy export in varh at the start of the interval */
  cumulativeReactiveEnergyExportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the reactive energy import in varh at the start of the interval */
  cumulativeReactiveEnergyImportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Report start time, in UTC */
  from: Scalars['Date'];
  /** Report end time, in UTC */
  to: Scalars['Date'];
};

export type ReturnDeviceInput = {
  comment?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  hasBeenUsed: Scalars['Boolean'];
};

export type ReturnDevicePayload = {
  __typename?: 'ReturnDevicePayload';
  device: DeviceInfo;
  success: Scalars['Boolean'];
};

/** Return information */
export type ReturnInfo = {
  __typename?: 'ReturnInfo';
  comment?: Maybe<Scalars['String']>;
  hasBeenUsed?: Maybe<Scalars['Boolean']>;
  returnedAt?: Maybe<Scalars['Date']>;
};

export type RevokeDeviceAccessInput = {
  deviceId: Scalars['String'];
  userId: Scalars['String'];
};

export type RushPricingDay = {
  __typename?: 'RushPricingDay';
  hours: HourRange;
  price: PriceSpecification;
};

/** The additional rush prices in a list, between two dates */
export type RushPricingPeriod = {
  __typename?: 'RushPricingPeriod';
  dateRange: DateRange;
  days: RushPricingWeek;
  holidaysIncluded: Scalars['Boolean'];
};

export type RushPricingWeek = {
  __typename?: 'RushPricingWeek';
  friday?: Maybe<RushPricingDay>;
  monday?: Maybe<RushPricingDay>;
  saturday?: Maybe<RushPricingDay>;
  sunday?: Maybe<RushPricingDay>;
  thursday?: Maybe<RushPricingDay>;
  tuesday?: Maybe<RushPricingDay>;
  wednesday?: Maybe<RushPricingDay>;
};

export type Search = {
  deviceId?: InputMaybe<Scalars['String']>;
  externalUserId?: InputMaybe<Scalars['String']>;
  meterId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

/** There are different prices for summer and winter, day and night */
export type SeasonalPrices = {
  __typename?: 'SeasonalPrices';
  summer: PricesForPartOfYear;
  winter: PricesForPartOfYear;
};

/** There are different prices for summer and winter, but not day and night */
export type SeasonalPricesWithoutDayAndNight = {
  __typename?: 'SeasonalPricesWithoutDayAndNight';
  summer: PricesForPartOfYearWithoutDayAndNight;
  winter: PricesForPartOfYearWithoutDayAndNight;
};

/**
 * Energy model were users can select type of energy model.
 *
 * Companies might have multiple types of energy models, and
 * let users select which model they would like use.
 */
export type SelectableEnergyModel = {
  __typename?: 'SelectableEnergyModel';
  constantPrice?: Maybe<ConstantPrice>;
  differentPricesDayAndNight?: Maybe<DifferentPricesDayAndNight>;
  differentPricesSeasonal?: Maybe<DifferentPricesSeasonal>;
  differentPricesSeasonalDayAndNight?: Maybe<DifferentPricesSeasonalDayAndNight>;
  type: EnergyModelType;
};

export type SessionInfo = {
  __typename?: 'SessionInfo';
  accessToken: Scalars['String'];
  userId: Scalars['String'];
};

export type SessionInfoWithoutAccessToken = {
  __typename?: 'SessionInfoWithoutAccessToken';
  startOfSecret: Scalars['String'];
  userId: Scalars['String'];
};

export type SetDeviceActivationStatusInput = {
  activationStatus: ActivationStatus;
  deviceIdentifier: DeviceIdentifierInput;
};

export type SetDeviceActivationStatusPayload = {
  __typename?: 'SetDeviceActivationStatusPayload';
  device: DeviceInfo;
  success: Scalars['Boolean'];
};

export type SetIntermediaryWebhookSettingInput = {
  intermediaryId?: InputMaybe<Scalars['String']>;
  secret: Scalars['String'];
};

export type SetIntermediaryWebhookSettingPayload = {
  __typename?: 'SetIntermediaryWebhookSettingPayload';
  webhookSetting: IntermediaryWebhookSetting;
};

export type ShipDeviceIdentifierInput = {
  claimId?: InputMaybe<Scalars['ClaimId']>;
  deviceId?: InputMaybe<Scalars['DeviceId']>;
};

export type ShipToUserInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  externalUserId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalAddress: PostalAddressInput;
  trackingLink?: InputMaybe<Scalars['String']>;
};

/** User information for tracking shipment and activation */
export type ShippedToUserInfo = {
  __typename?: 'ShippedToUserInfo';
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalAddress: PostalAddress;
  trackingLink?: Maybe<Scalars['String']>;
};

export type SmartChargingConsideration = {
  __typename?: 'SmartChargingConsideration';
  hasChargeAboveThreshold: Scalars['Boolean'];
  hasTimeEstimate: Scalars['Boolean'];
  isCharging: Scalars['Boolean'];
  isPluggedIn: Scalars['Boolean'];
  isSmartChargingCapable: Scalars['Boolean'];
  likelyToGenerateSavings: Scalars['Boolean'];
  needsSignificantCharge: Scalars['Boolean'];
  recentlyAtChargingLocation: Scalars['Boolean'];
  singleUser: Scalars['Boolean'];
  wontStopExistingChargingSession: Scalars['Boolean'];
};

export type SmartChargingPlan = {
  __typename?: 'SmartChargingPlan';
  endedAt?: Maybe<Scalars['String']>;
  estimatedFinishAt: Scalars['String'];
  nonSmartCost: Scalars['Float'];
  smartCost?: Maybe<Scalars['Float']>;
  startAt?: Maybe<Scalars['String']>;
  startConfirmedAt?: Maybe<Scalars['String']>;
  stopConfirmedAt?: Maybe<Scalars['String']>;
};

export enum SmartChargingPlanningStatusType {
  PlanEndedDeadlineChanged = 'PLAN_ENDED_DEADLINE_CHANGED',
  PlanEndedDisabled = 'PLAN_ENDED_DISABLED',
  PlanEndedFailed = 'PLAN_ENDED_FAILED',
  PlanEndedFinished = 'PLAN_ENDED_FINISHED',
  PlanEndedUnplugged = 'PLAN_ENDED_UNPLUGGED',
  PlanExecutingChargeInterrupted = 'PLAN_EXECUTING_CHARGE_INTERRUPTED',
  PlanExecutingOverridden = 'PLAN_EXECUTING_OVERRIDDEN',
  PlanExecutingStarted = 'PLAN_EXECUTING_STARTED',
  PlanExecutingStarting = 'PLAN_EXECUTING_STARTING',
  PlanExecutingStartFailed = 'PLAN_EXECUTING_START_FAILED',
  PlanExecutingStopped = 'PLAN_EXECUTING_STOPPED',
  PlanExecutingStoppedAwaitingPrices = 'PLAN_EXECUTING_STOPPED_AWAITING_PRICES',
  PlanExecutingStopping = 'PLAN_EXECUTING_STOPPING',
  PlanExecutingStopFailed = 'PLAN_EXECUTING_STOP_FAILED'
}

export type SmartChargingPolicy = {
  __typename?: 'SmartChargingPolicy';
  deadlineInUtcTime: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export enum SmartChargingStatusType {
  Considering = 'CONSIDERING',
  Disabled = 'DISABLED',
  Planned = 'PLANNED'
}

export type Subscription = {
  __typename?: 'Subscription';
  realtimeMeasurement?: Maybe<Measurement>;
};


export type SubscriptionRealtimeMeasurementArgs = {
  deviceId?: InputMaybe<Scalars['String']>;
  meterId?: InputMaybe<Scalars['String']>;
};

export type SubscriptionBilling = {
  __typename?: 'SubscriptionBilling';
  subscriptionType: SubscriptionType;
};

export type SubscriptionInput = {
  subscriptionType?: InputMaybe<SubscriptionType>;
};

export enum SubscriptionType {
  Business = 'BUSINESS',
  Personal = 'PERSONAL',
  Pilot = 'PILOT'
}

export type TestWebhookSettingInput = {
  callbackUrl: Scalars['String'];
  intermediaryId?: InputMaybe<Scalars['String']>;
};

export type TestWebhookSettingPayload = {
  __typename?: 'TestWebhookSettingPayload';
  success: Scalars['Boolean'];
};

/** Time zones, as reported by the _Internet Assigned Numbers Authority_ (IANA) database, but using an underscore for the forward slash. */
export enum Timezone {
  EuropeOslo = 'EUROPE_OSLO',
  Utc = 'UTC'
}

export type UpdateChargingLocationInput = {
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type UpdateChargingLocationPayload = {
  __typename?: 'UpdateChargingLocationPayload';
  chargingLocation: ChargingLocation;
};

export type UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  limitInWattHours: Scalars['Int'];
};

export type UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload = {
  __typename?: 'UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload';
  alertSetting: EstimatedHourlyActiveEnergyLimitWarningAlertSetting;
};

export type UpdateHourlyConsumptionLimitEstimationWarningAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  limitInWatts: Scalars['Int'];
};

export type UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload = {
  __typename?: 'UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload';
  alertSetting: HourlyConsumptionLimitEstimationWarningAlertSetting;
};

export type UpdateSmartChargingPolicyInput = {
  /**
   * Time for when the charging should be finished by.
   * hours:minutes, e.g. 08:00 or 23:59, in UTC time
   */
  deadlineInUtcTime: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  vehicleId: Scalars['String'];
};

export type UpdateSmartChargingPolicyPayload = {
  __typename?: 'UpdateSmartChargingPolicyPayload';
  smartChargingPolicy: SmartChargingPolicy;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  verifiedIdentities?: Maybe<Array<Identity>>;
};

export type UserIdentifier = ExternalUserIdentifier;

export type UserIdentifierInput = {
  externalUser?: InputMaybe<ExternalUserIdentifierInput>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  alertSettings?: Maybe<Array<AlertSetting>>;
  chargingLocations?: Maybe<Array<ChargingLocation>>;
  id: Scalars['ID'];
  intermediaries?: Maybe<Array<IntermediaryInfo>>;
  linkedIntegrationVendors?: Maybe<Array<IntegrationVendorInfo>>;
  sessions?: Maybe<Array<SessionInfoWithoutAccessToken>>;
  userIdentifier?: Maybe<UserIdentifier>;
  verifiedEmail?: Maybe<Scalars['String']>;
  verifiedIdentities?: Maybe<Array<Identity>>;
};


export type UserInfoIntermediariesArgs = {
  filter?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserInput = {
  userIdentifier: UserIdentifierInput;
};

export type UserNotAuthenticatedError = CustomError & {
  __typename?: 'UserNotAuthenticatedError';
  message: Scalars['String'];
  type: ErrorType;
};

export type UserNotPermittedError = CustomError & {
  __typename?: 'UserNotPermittedError';
  entityId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  resourceId?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  type: ErrorType;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  user: UserInfo;
};

export type VehicleSmartChargingStatusInput = {
  vehicleId: Scalars['String'];
};

export type VehicleSmartChargingStatusPayload = ConsideringSmartChargingStatus | DisabledSmartChargingStatus | PlannedSmartChargingStatus;

/**
 * Capacity model used by Fjellnett/Eidefoss
 *
 * Computation steps, according to <https://www.fjellnett.no/privatkunder/category1153.html>:
 *
 * - Fetch consumption data for each hour for the previous twelve months.
 * - Multiply the consumption for each hour with the corresponding month weights.
 * - Find the peak value of every week.
 * - Pick the highest (5) `weekPeaksPerYear` and compute the average for these (with two decimals).
 * - Multiply by `pricePerAverageKwh`.
 * - Add `baseFeePerYear`.
 * - Divide by 12.
 */
export type WeightedYearlyRollingPeaksInDifferentWeeks = {
  __typename?: 'WeightedYearlyRollingPeaksInDifferentWeeks';
  baseFeePerYear: PriceSpecification;
  monthWeights: MonthWeights;
  pricePerKwhForAverageOfPeaks: PriceSpecification;
  type: CapacityModelType;
  weekPeaksPerYear: Scalars['Int'];
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginPayload', session: { __typename?: 'SessionInfo', accessToken: string }, user: { __typename?: 'UserInfo', id: string, verifiedEmail?: string | null } } };

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register?: boolean | null };

export type SendResetPasswordEmailMutationVariables = Exact<{
  input: EmailActionInput;
}>;


export type SendResetPasswordEmailMutation = { __typename?: 'Mutation', sendResetPasswordEmail?: boolean | null };

export type SendEmailVerificationEmailMutationVariables = Exact<{
  input: EmailActionInput;
}>;


export type SendEmailVerificationEmailMutation = { __typename?: 'Mutation', sendEmailVerificationEmail?: boolean | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'UserInfo', id: string, verifiedEmail?: string | null } };

export type MyIntermediaryScopesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyIntermediaryScopesQuery = { __typename?: 'Query', me: { __typename?: 'UserInfo', intermediaries?: Array<{ __typename?: 'IntermediaryInfo', id: string, permissionScopes?: Array<string> | null }> | null } };

export type DashboardIntermediaryFragment = { __typename?: 'IntermediaryInfo', permissionScopes?: Array<string> | null };

export type MyIntermediaryDevicesSearchQueryVariables = Exact<{
  filter?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  search?: InputMaybe<Search>;
}>;


export type MyIntermediaryDevicesSearchQuery = { __typename?: 'Query', me: { __typename?: 'UserInfo', intermediaries?: Array<{ __typename?: 'IntermediaryInfo', id: string, devices?: Array<{ __typename?: 'DeviceInfo', deviceId: string, intermediaryId?: string | null, deviceName?: string | null, shippedAt?: string | null, type: string, activation?: { __typename?: 'Activation', status: ActivationStatus, updatedAt: any } | null, diagnostic?: { __typename?: 'DiagnosticInfo', lastMessageAt?: any | null, lastPingAt?: any | null, signalStrength?: number | null } | null, ownedBy?: { __typename?: 'OwnedByUserInfo', firstName: string, lastName: string, emailAddress?: string | null, phoneNumber?: string | null, externalUserId?: string | null, postalAddress?: { __typename?: 'OptionalFieldsPostalAddress', streetAddress?: string | null, postcode?: string | null, country?: PostcodeCountry | null } | null } | null, recentMeter?: { __typename?: 'RecentMeter', meterId: any, producer: MeterProducer } | null, returnInfo?: { __typename?: 'ReturnInfo', hasBeenUsed?: boolean | null, returnedAt?: any | null } | null, shippedTo?: { __typename?: 'ShippedToUserInfo', firstName: string, lastName: string, emailAddress?: string | null, phoneNumber?: string | null, externalUserId?: string | null, trackingLink?: string | null, postalAddress: { __typename?: 'PostalAddress', streetAddress: string, postcode: string, country: PostcodeCountry } } | null, subscription?: { __typename?: 'SubscriptionBilling', subscriptionType: SubscriptionType } | null }> | null }> | null } };

export type MyIntermediaryDeviceStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyIntermediaryDeviceStatsQuery = { __typename?: 'Query', me: { __typename?: 'UserInfo', intermediaries?: Array<{ __typename?: 'IntermediaryInfo', id: string, deviceStats?: { __typename?: 'DeviceStats', total: number, returnedDevices: number, devicesAtCustomer: number, devicesActivated: number, devicesDeactivated: number, devicesActivationReady: number } | null }> | null } };

export type DashboardDeviceFragment = { __typename?: 'DeviceInfo', deviceId: string, intermediaryId?: string | null, deviceName?: string | null, shippedAt?: string | null, type: string, activation?: { __typename?: 'Activation', status: ActivationStatus, updatedAt: any } | null, diagnostic?: { __typename?: 'DiagnosticInfo', lastMessageAt?: any | null, lastPingAt?: any | null, signalStrength?: number | null } | null, ownedBy?: { __typename?: 'OwnedByUserInfo', firstName: string, lastName: string, emailAddress?: string | null, phoneNumber?: string | null, externalUserId?: string | null, postalAddress?: { __typename?: 'OptionalFieldsPostalAddress', streetAddress?: string | null, postcode?: string | null, country?: PostcodeCountry | null } | null } | null, recentMeter?: { __typename?: 'RecentMeter', meterId: any, producer: MeterProducer } | null, returnInfo?: { __typename?: 'ReturnInfo', hasBeenUsed?: boolean | null, returnedAt?: any | null } | null, shippedTo?: { __typename?: 'ShippedToUserInfo', firstName: string, lastName: string, emailAddress?: string | null, phoneNumber?: string | null, externalUserId?: string | null, trackingLink?: string | null, postalAddress: { __typename?: 'PostalAddress', streetAddress: string, postcode: string, country: PostcodeCountry } } | null, subscription?: { __typename?: 'SubscriptionBilling', subscriptionType: SubscriptionType } | null };

export type DeviceListDeviceFragment = { __typename?: 'DeviceInfo', deviceId: string, intermediaryId?: string | null, deviceName?: string | null, shippedAt?: string | null, type: string, activation?: { __typename?: 'Activation', status: ActivationStatus, updatedAt: any } | null, diagnostic?: { __typename?: 'DiagnosticInfo', lastMessageAt?: any | null, lastPingAt?: any | null, signalStrength?: number | null } | null, ownedBy?: { __typename?: 'OwnedByUserInfo', firstName: string, lastName: string, emailAddress?: string | null, phoneNumber?: string | null, externalUserId?: string | null, postalAddress?: { __typename?: 'OptionalFieldsPostalAddress', streetAddress?: string | null, postcode?: string | null, country?: PostcodeCountry | null } | null } | null, recentMeter?: { __typename?: 'RecentMeter', meterId: any, producer: MeterProducer } | null, returnInfo?: { __typename?: 'ReturnInfo', hasBeenUsed?: boolean | null, returnedAt?: any | null } | null, shippedTo?: { __typename?: 'ShippedToUserInfo', firstName: string, lastName: string, emailAddress?: string | null, phoneNumber?: string | null, externalUserId?: string | null, trackingLink?: string | null, postalAddress: { __typename?: 'PostalAddress', streetAddress: string, postcode: string, country: PostcodeCountry } } | null, subscription?: { __typename?: 'SubscriptionBilling', subscriptionType: SubscriptionType } | null };

export type UpdateDeviceNameMutationVariables = Exact<{
  deviceId: Scalars['String'];
  deviceName: Scalars['String'];
}>;


export type UpdateDeviceNameMutation = { __typename?: 'Mutation', updateDeviceName?: { __typename?: 'DeviceInfo', deviceId: string, deviceName?: string | null } | null };

export type DeleteDeviceNameMutationVariables = Exact<{
  deviceId: Scalars['String'];
}>;


export type DeleteDeviceNameMutation = { __typename?: 'Mutation', deleteDeviceName?: { __typename?: 'DeviceInfo', deviceId: string } | null };

export type UpdateDeviceOwnerMutationVariables = Exact<{
  deviceId: Scalars['String'];
  deviceOwner: DeviceOwnerInput;
}>;


export type UpdateDeviceOwnerMutation = { __typename?: 'Mutation', updateDeviceOwner?: { __typename?: 'DeviceInfo', deviceId: string, ownedBy?: { __typename?: 'OwnedByUserInfo', firstName: string, lastName: string, emailAddress?: string | null, phoneNumber?: string | null, externalUserId?: string | null, postalAddress?: { __typename?: 'OptionalFieldsPostalAddress', streetAddress?: string | null, postcode?: string | null, country?: PostcodeCountry | null } | null } | null } | null };

export type SetDeviceActivationStatusMutationVariables = Exact<{
  deviceIdentifier: DeviceIdentifierInput;
  activationStatus: ActivationStatus;
}>;


export type SetDeviceActivationStatusMutation = { __typename?: 'Mutation', setDeviceActivationStatus: { __typename?: 'SetDeviceActivationStatusPayload', success: boolean, device: { __typename?: 'DeviceInfo', deviceId: string, activation?: { __typename?: 'Activation', status: ActivationStatus } | null } } };

export type DrawerMenuIntermediaryFragment = { __typename?: 'IntermediaryInfo', id: string };

export const DashboardIntermediaryFragmentDoc = gql`
    fragment DashboardIntermediary on IntermediaryInfo {
  permissionScopes
}
    `;
export const DeviceListDeviceFragmentDoc = gql`
    fragment DeviceListDevice on DeviceInfo {
  deviceId
  activation {
    status
    updatedAt
  }
  diagnostic {
    lastMessageAt
    lastPingAt
    signalStrength
  }
  intermediaryId
  deviceName
  ownedBy {
    firstName
    lastName
    emailAddress
    phoneNumber
    externalUserId
    postalAddress {
      streetAddress
      postcode
      country
    }
  }
  recentMeter {
    meterId
    producer
  }
  returnInfo {
    hasBeenUsed
    returnedAt
  }
  shippedAt
  shippedTo {
    firstName
    lastName
    emailAddress
    phoneNumber
    externalUserId
    trackingLink
    postalAddress {
      streetAddress
      postcode
      country
    }
  }
  subscription {
    subscriptionType
  }
  type
}
    `;
export const DashboardDeviceFragmentDoc = gql`
    fragment DashboardDevice on DeviceInfo {
  ...DeviceListDevice
}
    ${DeviceListDeviceFragmentDoc}`;
export const DrawerMenuIntermediaryFragmentDoc = gql`
    fragment DrawerMenuIntermediary on IntermediaryInfo {
  id
}
    `;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    session {
      accessToken
    }
    user {
      id
      verifiedEmail
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($input: RegisterInput!) {
  register(input: $input)
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const SendResetPasswordEmailDocument = gql`
    mutation SendResetPasswordEmail($input: EmailActionInput!) {
  sendResetPasswordEmail(input: $input)
}
    `;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(SendResetPasswordEmailDocument, options);
      }
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;
export const SendEmailVerificationEmailDocument = gql`
    mutation SendEmailVerificationEmail($input: EmailActionInput!) {
  sendEmailVerificationEmail(input: $input)
}
    `;
export type SendEmailVerificationEmailMutationFn = Apollo.MutationFunction<SendEmailVerificationEmailMutation, SendEmailVerificationEmailMutationVariables>;

/**
 * __useSendEmailVerificationEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationEmailMutation, { data, loading, error }] = useSendEmailVerificationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEmailVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailVerificationEmailMutation, SendEmailVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailVerificationEmailMutation, SendEmailVerificationEmailMutationVariables>(SendEmailVerificationEmailDocument, options);
      }
export type SendEmailVerificationEmailMutationHookResult = ReturnType<typeof useSendEmailVerificationEmailMutation>;
export type SendEmailVerificationEmailMutationResult = Apollo.MutationResult<SendEmailVerificationEmailMutation>;
export type SendEmailVerificationEmailMutationOptions = Apollo.BaseMutationOptions<SendEmailVerificationEmailMutation, SendEmailVerificationEmailMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    verifiedEmail
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MyIntermediaryScopesDocument = gql`
    query MyIntermediaryScopes {
  me {
    intermediaries {
      id
      permissionScopes
    }
  }
}
    `;

/**
 * __useMyIntermediaryScopesQuery__
 *
 * To run a query within a React component, call `useMyIntermediaryScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyIntermediaryScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyIntermediaryScopesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyIntermediaryScopesQuery(baseOptions?: Apollo.QueryHookOptions<MyIntermediaryScopesQuery, MyIntermediaryScopesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyIntermediaryScopesQuery, MyIntermediaryScopesQueryVariables>(MyIntermediaryScopesDocument, options);
      }
export function useMyIntermediaryScopesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyIntermediaryScopesQuery, MyIntermediaryScopesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyIntermediaryScopesQuery, MyIntermediaryScopesQueryVariables>(MyIntermediaryScopesDocument, options);
        }
export type MyIntermediaryScopesQueryHookResult = ReturnType<typeof useMyIntermediaryScopesQuery>;
export type MyIntermediaryScopesLazyQueryHookResult = ReturnType<typeof useMyIntermediaryScopesLazyQuery>;
export type MyIntermediaryScopesQueryResult = Apollo.QueryResult<MyIntermediaryScopesQuery, MyIntermediaryScopesQueryVariables>;
export const MyIntermediaryDevicesSearchDocument = gql`
    query MyIntermediaryDevicesSearch($filter: [String], $search: Search) {
  me {
    intermediaries(filter: $filter) {
      id
      devices(search: $search) {
        ...DashboardDevice
      }
    }
  }
}
    ${DashboardDeviceFragmentDoc}`;

/**
 * __useMyIntermediaryDevicesSearchQuery__
 *
 * To run a query within a React component, call `useMyIntermediaryDevicesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyIntermediaryDevicesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyIntermediaryDevicesSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useMyIntermediaryDevicesSearchQuery(baseOptions?: Apollo.QueryHookOptions<MyIntermediaryDevicesSearchQuery, MyIntermediaryDevicesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyIntermediaryDevicesSearchQuery, MyIntermediaryDevicesSearchQueryVariables>(MyIntermediaryDevicesSearchDocument, options);
      }
export function useMyIntermediaryDevicesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyIntermediaryDevicesSearchQuery, MyIntermediaryDevicesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyIntermediaryDevicesSearchQuery, MyIntermediaryDevicesSearchQueryVariables>(MyIntermediaryDevicesSearchDocument, options);
        }
export type MyIntermediaryDevicesSearchQueryHookResult = ReturnType<typeof useMyIntermediaryDevicesSearchQuery>;
export type MyIntermediaryDevicesSearchLazyQueryHookResult = ReturnType<typeof useMyIntermediaryDevicesSearchLazyQuery>;
export type MyIntermediaryDevicesSearchQueryResult = Apollo.QueryResult<MyIntermediaryDevicesSearchQuery, MyIntermediaryDevicesSearchQueryVariables>;
export const MyIntermediaryDeviceStatsDocument = gql`
    query MyIntermediaryDeviceStats {
  me {
    intermediaries {
      id
      deviceStats {
        total
        returnedDevices
        devicesAtCustomer
        devicesActivated
        devicesDeactivated
        devicesActivationReady
      }
    }
  }
}
    `;

/**
 * __useMyIntermediaryDeviceStatsQuery__
 *
 * To run a query within a React component, call `useMyIntermediaryDeviceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyIntermediaryDeviceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyIntermediaryDeviceStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyIntermediaryDeviceStatsQuery(baseOptions?: Apollo.QueryHookOptions<MyIntermediaryDeviceStatsQuery, MyIntermediaryDeviceStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyIntermediaryDeviceStatsQuery, MyIntermediaryDeviceStatsQueryVariables>(MyIntermediaryDeviceStatsDocument, options);
      }
export function useMyIntermediaryDeviceStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyIntermediaryDeviceStatsQuery, MyIntermediaryDeviceStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyIntermediaryDeviceStatsQuery, MyIntermediaryDeviceStatsQueryVariables>(MyIntermediaryDeviceStatsDocument, options);
        }
export type MyIntermediaryDeviceStatsQueryHookResult = ReturnType<typeof useMyIntermediaryDeviceStatsQuery>;
export type MyIntermediaryDeviceStatsLazyQueryHookResult = ReturnType<typeof useMyIntermediaryDeviceStatsLazyQuery>;
export type MyIntermediaryDeviceStatsQueryResult = Apollo.QueryResult<MyIntermediaryDeviceStatsQuery, MyIntermediaryDeviceStatsQueryVariables>;
export const UpdateDeviceNameDocument = gql`
    mutation UpdateDeviceName($deviceId: String!, $deviceName: String!) {
  updateDeviceName(deviceId: $deviceId, deviceName: $deviceName) {
    deviceId
    deviceName
  }
}
    `;
export type UpdateDeviceNameMutationFn = Apollo.MutationFunction<UpdateDeviceNameMutation, UpdateDeviceNameMutationVariables>;

/**
 * __useUpdateDeviceNameMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceNameMutation, { data, loading, error }] = useUpdateDeviceNameMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceName: // value for 'deviceName'
 *   },
 * });
 */
export function useUpdateDeviceNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceNameMutation, UpdateDeviceNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceNameMutation, UpdateDeviceNameMutationVariables>(UpdateDeviceNameDocument, options);
      }
export type UpdateDeviceNameMutationHookResult = ReturnType<typeof useUpdateDeviceNameMutation>;
export type UpdateDeviceNameMutationResult = Apollo.MutationResult<UpdateDeviceNameMutation>;
export type UpdateDeviceNameMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceNameMutation, UpdateDeviceNameMutationVariables>;
export const DeleteDeviceNameDocument = gql`
    mutation DeleteDeviceName($deviceId: String!) {
  deleteDeviceName(deviceId: $deviceId) {
    deviceId
  }
}
    `;
export type DeleteDeviceNameMutationFn = Apollo.MutationFunction<DeleteDeviceNameMutation, DeleteDeviceNameMutationVariables>;

/**
 * __useDeleteDeviceNameMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceNameMutation, { data, loading, error }] = useDeleteDeviceNameMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeleteDeviceNameMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeviceNameMutation, DeleteDeviceNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeviceNameMutation, DeleteDeviceNameMutationVariables>(DeleteDeviceNameDocument, options);
      }
export type DeleteDeviceNameMutationHookResult = ReturnType<typeof useDeleteDeviceNameMutation>;
export type DeleteDeviceNameMutationResult = Apollo.MutationResult<DeleteDeviceNameMutation>;
export type DeleteDeviceNameMutationOptions = Apollo.BaseMutationOptions<DeleteDeviceNameMutation, DeleteDeviceNameMutationVariables>;
export const UpdateDeviceOwnerDocument = gql`
    mutation UpdateDeviceOwner($deviceId: String!, $deviceOwner: DeviceOwnerInput!) {
  updateDeviceOwner(deviceId: $deviceId, deviceOwner: $deviceOwner) {
    deviceId
    ownedBy {
      firstName
      lastName
      emailAddress
      phoneNumber
      externalUserId
      postalAddress {
        streetAddress
        postcode
        country
      }
    }
  }
}
    `;
export type UpdateDeviceOwnerMutationFn = Apollo.MutationFunction<UpdateDeviceOwnerMutation, UpdateDeviceOwnerMutationVariables>;

/**
 * __useUpdateDeviceOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceOwnerMutation, { data, loading, error }] = useUpdateDeviceOwnerMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceOwner: // value for 'deviceOwner'
 *   },
 * });
 */
export function useUpdateDeviceOwnerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceOwnerMutation, UpdateDeviceOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceOwnerMutation, UpdateDeviceOwnerMutationVariables>(UpdateDeviceOwnerDocument, options);
      }
export type UpdateDeviceOwnerMutationHookResult = ReturnType<typeof useUpdateDeviceOwnerMutation>;
export type UpdateDeviceOwnerMutationResult = Apollo.MutationResult<UpdateDeviceOwnerMutation>;
export type UpdateDeviceOwnerMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceOwnerMutation, UpdateDeviceOwnerMutationVariables>;
export const SetDeviceActivationStatusDocument = gql`
    mutation SetDeviceActivationStatus($deviceIdentifier: DeviceIdentifierInput!, $activationStatus: ActivationStatus!) {
  setDeviceActivationStatus(
    input: {deviceIdentifier: $deviceIdentifier, activationStatus: $activationStatus}
  ) {
    device {
      activation {
        status
      }
      deviceId
    }
    success
  }
}
    `;
export type SetDeviceActivationStatusMutationFn = Apollo.MutationFunction<SetDeviceActivationStatusMutation, SetDeviceActivationStatusMutationVariables>;

/**
 * __useSetDeviceActivationStatusMutation__
 *
 * To run a mutation, you first call `useSetDeviceActivationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDeviceActivationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDeviceActivationStatusMutation, { data, loading, error }] = useSetDeviceActivationStatusMutation({
 *   variables: {
 *      deviceIdentifier: // value for 'deviceIdentifier'
 *      activationStatus: // value for 'activationStatus'
 *   },
 * });
 */
export function useSetDeviceActivationStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetDeviceActivationStatusMutation, SetDeviceActivationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDeviceActivationStatusMutation, SetDeviceActivationStatusMutationVariables>(SetDeviceActivationStatusDocument, options);
      }
export type SetDeviceActivationStatusMutationHookResult = ReturnType<typeof useSetDeviceActivationStatusMutation>;
export type SetDeviceActivationStatusMutationResult = Apollo.MutationResult<SetDeviceActivationStatusMutation>;
export type SetDeviceActivationStatusMutationOptions = Apollo.BaseMutationOptions<SetDeviceActivationStatusMutation, SetDeviceActivationStatusMutationVariables>;