import { useTheme } from "styled-components";
import { Typography } from "./Typography";

interface Props {
  text?: string;
}

export const ErrorText = ({ text }: Props): JSX.Element | null => {
  const theme = useTheme();
  return text ? (
    <Typography
      text={text}
      style={{
        color: theme.colors.text["500"],
      }}
      emphasized
    />
  ) : null;
};
