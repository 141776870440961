
import { useMutation } from "@apollo/client";
import { Col, Spacing } from "@hark-tech/components";
import { useEffect, useState } from "react";
import { DeleteMeDocument } from "../../generated/graphql/graphql";
import { ACCESS_TOKEN_STORAGE_KEY, SignOutButton, useAuth } from "../auth";
import { Button, ButtonVariant, Typography } from "../components";

export function DeleteMe(): JSX.Element {

  const {currentUser, signOut} = useAuth();
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  const [confirmationText, setConfirmationText] = useState<string>()
  const [timer, setTimer] = useState<number>();

  useEffect(() => {
    if (timer != null) {
      setTimeout(() => setTimer(timer - 1), 1000);
      setConfirmationText(`Your account was successfully deleted. You will be redirected in ${timer} seconds.`)
    }
    if (timer === 0) {
      signOut()
    }
  }, [timer, signOut]);

  const showConfirmationAndSignOut = () => {
    setTimer(5)
  }

  const [deleteMe, { error, loading }] =
  useMutation(DeleteMeDocument, {
    onCompleted: () => {
      showConfirmationAndSignOut();
    },
    variables: {input: {
      accessToken: accessToken ?? "",
      userId: currentUser?.id ?? "",
    }},
  });


  return (
    <Col $childSpacing={Spacing.MEDIUM}>
      <Typography text="You are about to delete your account, your connection to one or more monitoring devices, as well as your consumption data. This will be forever lost if you execute the deletion." />
      {confirmationText ? <Typography text={confirmationText}/> : <Button text="Delete my account" buttonVariant={ButtonVariant.RED} loading={loading} error={error?.message} onClick={deleteMe} />}
      <SignOutButton onSignedOut={() => undefined} />
    </Col>
  );
}
