import styled from "styled-components";
import { useAuth } from "./useAuth";

interface Props {
  onSignedOut: () => void;
}

export function SignOutButton(props: Props): JSX.Element {
  const { onSignedOut } = props;
  const { signOut } = useAuth();
  const signOutHandler = (): void => {
    signOut();
    onSignedOut();
  };
  return <SignOutLink onClick={signOutHandler}>Sign out</SignOutLink>;
}

const SignOutLink = styled.button`
  background: inherit;
  appearance: inherit;
  border: none;
  display: inline-block;
  font-family: inherit;
  font-size: ${({ theme }): string => theme.fontSizes.normal};
  font-weight: bold;
  color: ${({ theme }): string => theme.colors.blue[500]};
  text-decoration: underline;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;
