import {
  Col,
  InputType,
  Loader,
  Spacing,
} from "@hark-tech/components";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariant, Input, Typography } from "../components";
import { Routes } from "../routes";
import { AuthHeader } from "./AuthHeader";
import { ResetPasswordStatus } from "./AuthState";
import { useAuth } from "./useAuth";

export function Reset(): JSX.Element {
  const {
    resetPassword,
    resetPasswordState,
    emailInput: email,
    setEmailInput: setEmail,
  } = useAuth();
  const navigate = useNavigate();

  const resetPasswordWithEmail = async (email: string): Promise<void> => {
    await resetPassword({ email });
  };

  return (
    <div>
      <AuthHeader />
      {resetPasswordState.status === ResetPasswordStatus.RESETTING ? (
        <Loader />
      ) : resetPasswordState.status === ResetPasswordStatus.RESET_MESSAGE_VISIBLE ? (
        <Col>
          <Typography text="Please follow the instructions in a newly sent email to set a new password." />
          <Button
            onClick={(): void => {
              navigate(Routes.SIGN_IN);
            }}
            text="Go to login"
          />
        </Col>
      ) : (
        <Col $childSpacing={Spacing.MEDIUM}>
          <Typography text={"Enter your email below to reset your password"} />
          <Input
            label={"Email" }
            type={InputType.EMAIL}
            id="email"
            value={email}
            onChange={(email) => setEmail(email)}
          />
          {resetPasswordState.status === ResetPasswordStatus.FAILED && (
            <Typography
              text={resetPasswordState.error.message}
              style={{ color: "red" }}
            />
          )}

          <Button
            text="Reset"
            onClick={() => resetPasswordWithEmail(email)}
          />
          <Button
            text="Cancel"
            buttonVariant={ButtonVariant.WHITE}
            onClick={(): void => {
              navigate(Routes.SIGN_IN);
            }}
          />
        </Col>
      )}
    </div>
  );
}
