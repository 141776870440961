import { useLazyQuery, useMutation } from "@apollo/client";
import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { apolloErrorToServerError } from "../../domain/apolloErrorToServerError";
import {
  LoginDocument,
  MeDocument,
  SendResetPasswordEmailDocument,
} from "../../generated/graphql";
import {
  AuthState,
  ResetParams,
  ResetPasswordState,
  ResetPasswordStatus,
  SignInParams,
  SignInState,
  SignInStatus,
  User,
} from "./AuthState";

export const ACCESS_TOKEN_STORAGE_KEY = "accessToken";

export const AuthContext = createContext<AuthState | null>(null);
AuthContext.displayName = "AuthContext";

export function AuthProvider(
  props: PropsWithChildren<Record<never, never>>
): JSX.Element {
  const redirectUri = window.location.protocol + "//" + window.location.host;

  const [token, setToken, clearToken] = useLocalStorage<string>(
    ACCESS_TOKEN_STORAGE_KEY,
    undefined,
    {
      raw: true,
    }
  );

  const [emailInput, setEmailInput] = useState("");
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isFetchingUser, setFetchingUser] = useState<boolean>(true);

  const [signInState, setSignInState] = useState<SignInState>({
    status: SignInStatus.INITIAL,
  });

  const [resetPasswordState, setResetPasswordState] =
    useState<ResetPasswordState>({
      status: ResetPasswordStatus.INITIAL,
    });

  const [login, { error: loginError, client }] =
    useMutation(LoginDocument, {
      onCompleted: (data) => {
        const {
          login: { user, session },
        } = data;
        setCurrentUser({
          email: user.verifiedEmail ?? null,
          id: user.id,
        });
        setToken(session.accessToken);
        setSignInState({ status: SignInStatus.SUCCESS });
      }
    });

  const [sendResetPasswordEmail] = useMutation(SendResetPasswordEmailDocument);

  const [getMe, { data: meData, error: meError }] = useLazyQuery(MeDocument);

  useEffect(
    function fetchMeDataWhenTokenIsFetched() {
      if (!token) {
        setFetchingUser(false);
      }
      if (token != null && currentUser == null) {
        getMe();
      }
    },
    [token, currentUser, getMe]
  );

  useEffect(
    function setCurrentUserFromMeData() {
      if (meData?.me) {
        const { id, verifiedEmail } = meData.me;
        setCurrentUser({
          email: verifiedEmail ?? null,
          id,
        });
        setFetchingUser(false);
      }
    },
    [meData]
  );

  useEffect(
    function signOutUserOnAuthError() {
      if (meError && meError.graphQLErrors) {
        const [firstError] = meError.graphQLErrors;
        const userNotFoundInDatabase =
          firstError.extensions?.code === "NOT_FOUND";
        const sessionExpired =
          firstError.extensions?.code === "UNAUTHENTICATED";
        if (userNotFoundInDatabase || sessionExpired) {
          signOut();
          setFetchingUser(false);
        }
      }
    },
    [meError]
  );

  useEffect(
    function clearCacheOnTokenCleared() {
      if (token == null) {
        // `resetStore` clears the cache. We stop the apollo client first to avoid refetching
        client.stop();
        client.resetStore();
        setCurrentUser(null);
        setEmailInput("");
      }
    },
    [token, client]
  );

  // useEffect(() => {
  //   if (loginData != null) {
  //     const {
  //       login: { user, session },
  //     } = loginData;
  //     setCurrentUser({
  //       email: user.verifiedEmail ?? null,
  //       id: user.id,
  //     });
  //     setToken(session.accessToken);
  //     setSignInState({ status: SignInStatus.SUCCESS });
  //   }}, [loginData, token, setToken]
  // );

  useEffect(
    function setSignInStateOnLoginError() {
      if (loginError) {
        const serverError = apolloErrorToServerError(loginError);
        setSignInState({
          error: serverError,
          status: SignInStatus.FAILED,
        });
      }
    },
    [loginError]
  );

  const signInWithEmailAndPassword = async ({
    email,
    password,
  }: SignInParams): Promise<void> => {
    setSignInState({ status: SignInStatus.SIGNING_IN });
    try {
      await login({
        variables: { input: { email, password } },
      });
    } catch {
      // handled in useEffect for loginError
    }
  };

  const resetPassword = async ({ email }: ResetParams): Promise<void> => {
    setResetPasswordState({ status: ResetPasswordStatus.RESETTING });
    try {
      await sendResetPasswordEmail({
        variables: { input: { email, redirectUri } },
      });
      setResetPasswordState({
        status: ResetPasswordStatus.RESET_MESSAGE_VISIBLE,
      });
    } catch (error) {
      setResetPasswordState({
        error: apolloErrorToServerError(error),
        status: ResetPasswordStatus.FAILED,
      });
    }
  };

  const signOut = (): void => {
    clearToken();
  };

  const authState: AuthState = {
    clearLoginState: () => setSignInState({ status: SignInStatus.INITIAL }),
    currentUser,
    emailInput,
    isFetchingUser,
    resetPassword,
    resetPasswordState,
    setCurrentUser,
    setEmailInput,
    signIn: signInWithEmailAndPassword,
    signInState,
    signOut,
  };

  return (
    <AuthContext.Provider value={authState}>
      {props.children}
    </AuthContext.Provider>
  );
}
