import { Button as BaseButton, LoaderProps } from "@hark-tech/components";
import { PropsWithChildren } from "react";
import { useTheme } from "styled-components";
import { ErrorText } from "./ErrorText";

interface ButtonProps {
  loading?: boolean;
  error?: string;
  text: string;
  buttonVariant?: ButtonVariant;
  disabled?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  loaderStyle?: LoaderProps;
  onClick: () => void;
}

export enum ButtonVariant {
  WHITE,
  GREEN,
  RED,
}

export const Button = (props: PropsWithChildren<ButtonProps>): JSX.Element => {
  const theme = useTheme();
  const { buttonVariant = ButtonVariant.GREEN, error } = props;

  let backgroundColor = theme.colors.text["200"];
  let border = `2.5px solid ${theme.colors.text["200"]}`;
  let textColor = theme.colors.text["500"];
  if (buttonVariant === ButtonVariant.GREEN) {
    // eslint-disable-next-line prefer-destructuring
    backgroundColor = theme.colors.success["500"];
    // textColor = theme.colors.success["500"];
    border = "none";
  }
  if (buttonVariant === ButtonVariant.RED) {
    // eslint-disable-next-line prefer-destructuring
    backgroundColor = theme.colors.danger["500"];
    textColor = theme.colors.background["500"];
    border = "none";
  }
  return (
    <>
      {error && <ErrorText text={error} />}
      <BaseButton
        {...props}
        backgroundColor={backgroundColor}
        textColor={textColor}
        border={border}
        borderRadius="40px"
        size="LARGE"
        fullWidth
        style={{
          fontSize: "14px",
          fontWeight: "900",
          textTransform: "uppercase",
        }}
      />
    </>
  );
};
