import { Col, InputType, Loader, Spacing } from "@hark-tech/components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariant, Input, Typography } from "../components";
import { Routes } from "../routes";
import { AuthHeader } from "./AuthHeader";
import { SignInStatus } from "./AuthState";
import { useAuth } from "./useAuth";

export function SignIn(): JSX.Element {
  const [password, setPassword] = useState("");
  const {
    signIn,
    signInState,
    emailInput: email,
    setEmailInput: setEmail,
  } = useAuth();
  const navigate = useNavigate();

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<void> => {
    await signIn({
      email,
      password,
    });
    navigate(Routes.INDEX);
  };

  return (
    <Col>
      <AuthHeader />
      {signInState.status === SignInStatus.SIGNING_IN ? (
        <Loader />
      ) :  (
        <Col $childSpacing={Spacing.MEDIUM}>
          <form >
            <Input
              label={"Email"}
              type={InputType.EMAIL}
              id="email"
              focus
              value={email}
              onChange={(email) =>
                setEmail(email)
              }
            />
            <Input
              label={"Password"}
              type={InputType.PASSWORD}
              id="password"
              value={password}
              onChange={(password) =>
                setPassword(password)
              }
            />
          </form>
          {signInState.status === SignInStatus.FAILED && (
            <Typography text={signInState.error.message} style={{color:"red"}} />
          )}
          <Button text="Sign in" onClick={() => signInWithEmailAndPassword(email, password)} />

          <Button
            text="Forgotten password?"
            buttonVariant={ButtonVariant.WHITE}
            onClick={(): void => {
              navigate(Routes.RESET_PASSWORD);
            }}
          />
        </Col>
      )}
    </Col>
  );
}

