import {
  Typography as BaseTypography,
  TypographyProps,
} from "@hark-tech/components";
import { PropsWithChildren } from "react";
import { useTheme } from "styled-components";

export const Typography = (
  props: PropsWithChildren<TypographyProps>
): JSX.Element => {
  const theme = useTheme();
  return (
    <BaseTypography
      style={{
        color: theme.colors.text["500"],
      }}
      {...props}
    />
  );
};
